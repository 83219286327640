<p-menu #avatarAction appendTo="body" [popup]="true" [model]="avatarActions" [styleClass]="'style-2022'"></p-menu>
<div id="system_status" [matTooltip]="" *ngIf="aI.isDev" (click)="aI.showStatus()"><i [matTooltip]="url" class="fa fa-question" style="font-size: 24px; color: black"></i></div>
<div id="wrapper" [ngClass]="!aI.isLoggedIn$ && !aI.hash_id ? 'bg' : 'app_wrapper'">
  <div id="left" *ngIf="aI.isLoggedIn$ && (!aI.crm || aI.showNavigation) && !aI.hash_id && translationReady" [ngClass]="aI.menuToggle ? 'menu_180' : 'menu_40'">
    <!-- Feature flag for stlyes2022 Collapsed menu -->
    <div *ngIf="!aI.menuToggle">
      <div class="logo-box logo-container" style="width: 66px; margin: -4px">
        <a *ngIf="add_xdl_btn === true" href="{{iconUrl}}" class="logo-text">
          <i class="small-logo" style="width: 50px; margin: 15px">
            <img src="{{ aI.companyIcon }}" alt="{{ aI.companyIcon }}" style="width: 100%" />
          </i>
        </a>
        <a *ngIf="add_xdl_btn === false" routerLink="{{iconUrl}}" class="logo-text">
          <i class="small-logo" style="width: 50px; margin: 15px">
            <img src="{{ aI.companyIcon }}" alt="{{ aI.companyIcon }}" style="width: 100%" />
          </i>
        </a>
      </div>
      <!-- Home -->
      <ul class="menu accordion-menu" [ngStyle]="{ width: aI.menuToggle ? '180px' : '75px' }" style="z-index: 1; overflow: hidden">
        <li [routerLinkActive]="['active']">
          <a *ngIf="add_xdl_btn === true" href="{{iconUrl}}">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.dashboard.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined collapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/Dashboard.png" class="xf-icon">
            </span>
            <p *ngIf="aI.menuToggle">{{ trans.trans.dashboard.value }}</p>
            <span class="active-page"></span>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.dashboard"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.dashboard"
            ></app-editTranslation>
          </a>
          <a *ngIf="add_xdl_btn === false" routerLink="{{iconUrl}}">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.dashboard.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined collapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/Dashboard.png" class="xf-icon">
            </span>
            <p *ngIf="aI.menuToggle">{{ trans.trans.dashboard.value }}</p>
            <span class="active-page"></span>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.dashboard"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.dashboard"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Hypotheses -->
        <li *ngIf="isValueInsightUser" [routerLinkActive]="['active']">
          <a routerLink="/scb">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.research.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined collapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/Line Chart.png" class="xf-icon">
            </span>
            <p *ngIf="aI.menuToggle">{{ trans.trans.research.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.research"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.research"
            ></app-editTranslation>
          </a>
        </li>
        <li *ngIf="isValuePositionUser" [routerLinkActive]="['active']" [ngClass]="{ 'active-secondary': currentNav === 'hypothesis' }">
          <a routerLink="/valueposition">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.reach.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined collapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/Line Chart.png" class="xf-icon">
            </span>
            <p *ngIf="aI.menuToggle">{{ trans.trans.reach.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.reach"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.reach"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Cases -->
        <li *ngIf="isValuePropUser" [routerLinkActive]="['active']" [ngClass]="{ 'active-secondary': currentNav === 'case' }">
          <a [routerLink]="masterValuePropFeature ? ['/mastervalueprop'] : ['/valueprop']">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.revise.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined collapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/briefcase.png" class="xf-icon">
            </span>
            <p *ngIf="aI.menuToggle">{{ trans.trans.revise.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.revise"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.revise"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Value Trackers -->
        <li [hidden]="true" *ngIf="isValueRealizationUser && !aI.feature58" [routerLinkActive]="['active']">
          <a routerLink="/valuerealization">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.retain.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined collapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/Chart Bar.png" class="xf-icon">
            </span>
            <p *ngIf="aI.menuToggle">{{ trans.trans.retain.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.retain"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.retain"
            ></app-editTranslation>
          </a>
        </li>
        <li [routerLinkActive]="['active']" *ngIf="isValueRealizationUser && aI.feature58">
          <a routerLink="/trackers">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.value_trackers.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined collapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/Chart Bar.png" class="xf-icon">
            </span>
            <p *ngIf="aI.menuToggle">{{ trans.trans.value_trackers.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.retain"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.value_trackers"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Value Realized -->
        <li *ngIf="isValueRealized2 && customerSuccessPriv" [routerLinkActive]="['active']">
          <a routerLink="/valuerealized">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.value_realized.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined collapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/Chart Bar.png" class="xf-icon">
            </span>
            <p *ngIf="aI.menuToggle">{{ trans.trans.value_realized.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.value_realized"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.value_realized"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Value Groups -->
        <li *ngIf="vpg" [routerLinkActive]="['active']">
          <a routerLink="/vpg">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.valueGroups.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined collapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/value groups.png" class="xf-icon">
            </span>
            <p *ngIf="aI.menuToggle">{{ trans.trans.valueGroups.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.model"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.valueGroups"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Models -->
        <li *ngIf="isSolutionAdmin && !modelsV2" [routerLinkActive]="['active']">
          <a routerLink="/models">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.model.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined collapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/models.png" class="xf-icon">
            </span>
            <p *ngIf="aI.menuToggle">{{ trans.trans.model.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.model"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.model"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Models V2 -->
        <li *ngIf="isSolutionAdmin && modelsV2" [routerLinkActive]="['active']" [ngClass]="{ 'active-secondary': currentNav === 'models' }">
          <a routerLink="/modelsv2">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.model.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined collapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/models.png" class="xf-icon">
            </span>
            <p *ngIf="aI.menuToggle">{{ trans.trans.model.value }} V2</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.model"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.model"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Case Studies -->
        <li *ngIf="feature18" [routerLinkActive]="['active']">
          <a routerLink="/case_study">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.caseStudies.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined collapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/case studies.png" class="xf-icon">
            </span>
            <p *ngIf="aI.menuToggle">{{ trans.trans.caseStudies.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.model"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.caseStudies"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Spacer -->
        <li style="margin-top: auto"></li>
        <!-- Settings -->
        <li [routerLinkActive]="['active']" *ngIf="hasUserPrivileges && hasSettingsLinks" [ngClass]="{ 'active-secondary': currentNav === 'settings' }">
          <a [routerLink]="['/settings']">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.settings.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined collapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/settings.png" class="xf-icon">
            </span>
            <p *ngIf="aI.menuToggle">{{ trans.trans.settings.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.learn"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.settings"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Edit Translations -->
        <li *ngIf="accountAdmin">
          <a class="clickable" (click)="aI.setEditToggleTranslations()">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.translations.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined collapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/translations.png" class="xf-icon">
            </span>
            <span>
              <p *ngIf="aI.menuToggle">{{ trans.trans.translations.value }}</p>
              <app-editTranslation
                *ngIf="showTranslate"
                (callback)="getTranslations()"
                key="menu.learn"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.translations"
              ></app-editTranslation>
            </span>
          </a>
        </li>
      </ul>
    </div>
    <!-- Feature flag for un-collapsed -->
    <div *ngIf="aI.menuToggle">
      <div class="logo-box logo-container logo-container-height">
        <a *ngIf="add_xdl_btn === true" href="{{iconUrl}}" class="logo-text">
          <i class="logo"
            ><img
              [src]="aI.companyLogo"
              [alt]="aI.companyLogo"
              [ngStyle]="{ margin: isDefaultcompanyLogo === true ? '20px auto' : 'auto' }"
              style="display: block; height: auto; width: auto; max-width: 90%; max-height: 70px"
          /></i>
        </a>
        <a *ngIf="add_xdl_btn === false" routerLink="{{iconUrl}}" class="logo-text">
          <i class="logo"
            ><img
              [src]="aI.companyLogo"
              [alt]="aI.companyLogo"
              [ngStyle]="{ margin: isDefaultcompanyLogo === true ? '20px auto' : 'auto' }"
              style="display: block; height: auto; width: auto; max-width: 90%; max-height: 70px"
          /></i>
        </a>
      </div>

      <ul class="menu accordion-menu" [ngStyle]="{ width: aI.menuToggle ? '180px' : '58px' }" style="z-index: 1; overflow: hidden; display: flex; flex-direction: column">
        <!-- Dashboard -->
        <li [routerLinkActive]="['active']">
          <a routerLink="/dashboard" class="menu-item">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.dashboard.value : ''"
              matTooltipPosition="right"
              aria-hidden="true"
              class="menu-icon material-icons-outlined uncollapsed-menu-item"
            >
              <img src="assets/images/Dashboard.png" class="xf-icon">
            </span>
            <p class="menu-text" *ngIf="aI.menuToggle">{{ trans.trans.dashboard.value }}</p>
            <span class="active-page"></span>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.dashboard"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.dashboard"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Hypotheses -->
        <li *ngIf="isValueInsightUser" [routerLinkActive]="['active']">
          <a routerLink="/scb" class="menu-item">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.research.value : ''"
              matTooltipPosition="right"
              aria-hidden="true"
              class="menu-icon material-icons-outlined uncollapsed-menu-item"
            >
              <img src="assets/images/Line Chart.png" class="xf-icon">
            </span>
            <p class="menu-text" *ngIf="aI.menuToggle">{{ trans.trans.research.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.research"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.research"
            ></app-editTranslation>
          </a>
        </li>
        <li *ngIf="isValuePositionUser" [routerLinkActive]="['active']" [ngClass]="{ 'active-secondary': currentNav === 'hypothesis' }">
          <a routerLink="/valueposition" class="menu-item">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.reach.value : ''"
              matTooltipPosition="right"
              aria-hidden="true"
              class="menu-icon material-icons-outlined uncollapsed-menu-item"
            >
              <img src="assets/images/Line Chart.png" class="xf-icon">
            </span>
            <p class="menu-text" *ngIf="aI.menuToggle">{{ trans.trans.reach.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.reach"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.reach"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Cases -->
        <li *ngIf="isValuePropUser" [routerLinkActive]="['active']" [ngClass]="{ 'active-secondary': currentNav === 'case' }">
          <a [routerLink]="masterValuePropFeature ? ['/mastervalueprop'] : ['/valueprop']" class="menu-item">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.revise.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined uncollapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/briefcase.png" class="xf-icon">
            </span>
            <p class="menu-text" *ngIf="aI.menuToggle">{{ trans.trans.revise.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.revise"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.revise"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Value Trackers -->
        <li [hidden]="true" *ngIf="isValueRealizationUser && !aI.feature58" [routerLinkActive]="['active']">
          <a routerLink="/valuerealization" class="menu-item">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.retain.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined uncollapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/Chart Bar.png" class="xf-icon">enter_focus
            </span>
            <p class="menu-text" *ngIf="aI.menuToggle">{{ trans.trans.retain.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.retain"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.retain"
            ></app-editTranslation>
          </a>
        </li>
        <li [routerLinkActive]="['active']" *ngIf="isValueRealizationUser && aI.feature58">
          <a routerLink="/trackers" class="menu-item">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.value_trackers.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined uncollapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/Chart Bar.png" class="xf-icon">
            </span>
            <p class="menu-text" *ngIf="aI.menuToggle">{{ trans.trans.value_trackers.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.retain"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.value_trackers"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Value Realized -->
        <li *ngIf="isValueRealized2 && customerSuccessPriv" [routerLinkActive]="['active']">
          <a routerLink="/valuerealized" class="menu-item">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.value_realized.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined uncollapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/Chart Bar.png" class="xf-icon">
            </span>
            <p class="menu-text" *ngIf="aI.menuToggle">{{ trans.trans.value_realized.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.value_realized"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.value_realized"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Value Groups -->
        <li *ngIf="vpg" [routerLinkActive]="['active']">
          <a routerLink="/vpg" class="menu-item">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.valueGroups.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined uncollapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/value groups.png" class="xf-icon">
            </span>
            <p class="menu-text" *ngIf="aI.menuToggle">{{ trans.trans.valueGroups.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.model"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.valueGroups"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Models -->
        <li *ngIf="isSolutionAdmin && !modelsV2" [routerLinkActive]="['active']">
          <a routerLink="/models" class="menu-item">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.model.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined uncollapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/models.png" class="xf-icon">
            </span>
            <p class="menu-text" *ngIf="aI.menuToggle">{{ trans.trans.model.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.model"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.model"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Models V2 -->
        <li *ngIf="isSolutionAdmin && modelsV2" [routerLinkActive]="['active']" [ngClass]="{ 'active-secondary': currentNav === 'models' }">
          <a routerLink="/modelsv2" class="hoverable" class="menu-item">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.model.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined uncollapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/models.png" class="xf-icon">
            </span>
            <p class="menu-text" *ngIf="aI.menuToggle">{{ trans.trans.model.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.model"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.model"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Case Studies -->
        <li *ngIf="feature18" [routerLinkActive]="['active']">
          <a routerLink="/case_study" class="menu-item">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.model.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined uncollapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/case studies.png" class="xf-icon">
            </span>
            <p class="menu-text" *ngIf="aI.menuToggle">{{ trans.trans.caseStudies.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.model"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.caseStudies"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Spacer -->
        <li style="margin-top: auto"></li>
        <!-- Settings -->
        <li [routerLinkActive]="['active']" *ngIf="hasUserPrivileges && hasSettingsLinks" [ngClass]="{ 'active-secondary': currentNav === 'settings' }">
          <a [routerLink]="['/settings']" class="menu-item">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.settings.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined uncollapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/settings.png" class="xf-icon">
            </span>
            <p class="menu-text" *ngIf="aI.menuToggle">{{ trans.trans.settings.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.learn"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.settings"
            ></app-editTranslation>
          </a>
        </li>
        <!-- Edit Translations -->
        <li *ngIf="accountAdmin">
          <a class="clickable" (click)="aI.setEditToggleTranslations()" class="menu-item">
            <span
              [matTooltip]="!aI.menuToggle ? trans.trans.translations.value : ''"
              matTooltipPosition="right"
              class="menu-icon material-icons-outlined uncollapsed-menu-item"
              aria-hidden="true"
            >
              <img src="assets/images/translations.png" class="xf-icon">
            </span>
            <p class="menu-text" *ngIf="aI.menuToggle">{{ trans.trans.translations.value }}</p>
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="menu.learn"
              [component]="trans.config.component"
              [isTranslationV2]="true"
              [transObj]="trans.trans.translations"
            ></app-editTranslation>
          </a>
        </li>
        <li [routerLinkActive]="['active']" *ngIf="add_xdl_btn">
        </li>
        </ul>
        <p *ngIf="!feature122" style="text-align: center" class="muted-t">Powered by Xfactor.io &reg;</p>
      </div>
    </div>

  <div id="center" [ngClass]="aI.funcSetMainCenterClass()" [ngClass]="!aI.isLoggedIn$ || aI.hash_id || aI.showNavigation ? 'center_no_padding' : ''">
    <div class="navbar" *ngIf="aI.isLoggedIn$ && !aI.isIntake$ && !aI.hash_id && (!aI.crm || aI.showNavigation)">
      <div class="navbar-inner">
        <div class="topmenu-outer" style="margin-left: 0px !important">
          <div class="left-side">
            <a *ngIf="!aI.hash_id" (click)="aI.menuToggle = !aI.menuToggle; aI.funcSetMainCenterClass()" class="new-style">
              <span class="material-icons-round"> menu </span>
            </a>

            <div class="col-xs-3">
              <app-global-search-header></app-global-search-header>
            </div>
          </div>

          <div class="right-side">
            <div class="avatar-section clickable topmenu-outer" (click)="avatarAction.toggle($event)">
              <div class="avatar">
                <img class="avatar-profile-image" [src]="loggedUser?.profile_pic_filepath !== '' ? loggedUser?.profile_pic_filepath : profilepicdefaultpath" />
              </div>
              <div class="name-role">
                <h2>{{ loggedUser?.first_name }} {{ loggedUser?.last_name }}</h2>
                <span>{{ loggedUser?.role_type }}</span>
              </div>
              <i class="fa fa-ellipsis-v"></i>
              <div class="hover-background"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container_interior">
      <div id="main-wrapper" class="route-container" style="padding: 0px !important; margin-top: 0px">
        <div class="row" *ngIf="aI.crm">
          <div class="col-xs-12">
            <a *ngIf="!aI.feature42 && aI.showNavToggle" (click)="aI.toggleNavigation()" [ngStyle]="{ color: aI.showNavigation ? 'orange' : 'inherit' }" class="clickable pull-right"
              >Toggle Navigation</a
            >
          </div>
        </div>

        <alert *ngIf="aI.isLoggedIn$ || aI.tokenExpired || aI.redirectLogin"></alert>
        <notification></notification>
        <router-outlet></router-outlet>
      </div>
      <spinner></spinner>
      <!--<app-mikeynav *ngIf="add_xdl_btn && aI.isLoggedIn$"></app-mikeynav>-->
      <div class="page-footer" style="height: 0px"></div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="aI.toggleSupport" styleClass="keywest_sidebar p-sidebar-lg" position="right">
  <app-support *ngIf="aI.toggleSupport"></app-support>
</p-sidebar>
