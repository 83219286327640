<div class="row">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>

<p-menu #row appendTo="body" [popup]="true" [model]="rowLimit" [styleClass]="'style-2022'"></p-menu>

<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-10">
          <div class="title">
            {{ trans.trans.manageusers.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="trans.manag"
              [component]="trans.config.component"
              [transObj]="trans.trans.manageusers"
              [isTranslationV2]="true"
            ></app-editTranslation>
          </div>
        </div>
        <div class="col-xs-2">
          <a *ngIf="aI.addRemoveUsers" (click)="AddUserForm()" class="btn btn_customizable pull-right">{{ trans.trans.add.value }}</a>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 20px">
      <div class="col-xs-4">
        <div class="input-group keywest_search">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input
            [(ngModel)]="strSearch"
            (keyup)="tableUsers.filter($any($event).target.value, 'name', 'contains')"
            type="text"
            placeholder="{{ trans.trans.search.value }}"
            class="form-control"
          />
          <span *ngIf="strSearch !== ''" (click)="strSearch = ''; tableUsers.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
        </div>
      </div>
      <div class="col-xs-8">
        <span class="clickable pull-right" (click)="row.toggle($event)">{{ trans.trans.rowLimit.value }} {{ pageLimit }} <i class="fa fa-chevron-down"></i></span>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
        <p-table #tableUsers [value]="usersGridData" [paginator]="true" [rows]="pageLimit" styleClass="keywest_table" tableStyleClass="keywest_table">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th>
                {{ trans.trans.name.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  key="trans.name"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.name"
                ></app-editTranslation>
              </th>
              <th>
                {{ trans.trans.role.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  key="trans.role"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.role"
                ></app-editTranslation>
              </th>
              <th>
                {{ trans.trans.email.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  key="trans.email"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.email"
                ></app-editTranslation>
              </th>
              <th>
                {{ trans.trans.key.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  key="trans.key"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.key"
                ></app-editTranslation>
              </th>
              <th>
                <span class="pull-right marg-h">
                  {{ trans.trans.actions.value }}
                </span>
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  key="trans.action"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.actions"
                ></app-editTranslation>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-user>
            <tr>
              <td (click)="updateUserForm(user)" class="link clickable">{{ user.name }}</td>
              <td>{{ user.role_name }}</td>
              <td>{{ user.email }}</td>
              <td>
                <div class="flex">
                  <i *ngIf="user.crm_key" class="fa fa-check" style="margin-right: 10px"></i>
                  <span *ngIf="user.crm_key && isUserAdmin">
                    <app-are-you-sure
                      pTooltip="disconnect user from CRM"
                      tooltipPosition="top"
                      [message]="trans.trans.areYouSure.value"
                      text=""
                      icon="fieldset_icon fa fa-trash"
                      (callbackYes)="disconectFromCRM(user)"
                    ></app-are-you-sure>
                  </span>
                </div>
              </td>
              <td>
                <span class="keywest_icon_group pull-right">
                  <span><i (click)="updateUserForm(user)" [matTooltip]="trans.trans.edit.value" matTooltipPosition="above" class="clickable fa fa-pencil"></i></span>
                  <span>
                    <app-are-you-sure
                      matTooltip="reset user password"
                      matTooltipPosition="above"
                      [message]="trans.trans.areYouSure.value"
                      text=""
                      icon="fieldset_icon fa fa-refresh"
                      (callbackYes)="resetUserPassword(user)"
                    ></app-are-you-sure>
                  </span>
                  <span>
                    <app-are-you-sure
                    *ngIf="aI.addRemoveUsers"
                      [matTooltip]="trans.trans.delete.value"
                      matTooltipPosition="above"
                      [message]="trans.trans.areYouSure.value"
                      text=""
                      icon="fieldset_icon fa fa-trash"
                      (callbackYes)="open(user)"
                    ></app-are-you-sure>
                  </span>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>

<p-sidebar
  (onShow)="onUserformShow()"
  (onHide)="defaultUserSidebar()"
  [(visible)]="toggleEditUser"
  *ngIf="toggleEditUser"
  styleClass="keywest_sidebar p-sidebar-lg"
  position="right"
>
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ sidebarTitle?.value }}
    </div>
    <div class="sidebar_container">
      <div class="selection-wrapper">
        <p-selectButton optionDisabled="inactive" [(ngModel)]="currentPage" name="currentPage" [options]="pageOptions" styleClass="keywest_select_button center">
          <ng-template let-item>
            <!-- number -->
            <span class="circle light-l">
              {{ item.value }}
            </span>
            <!-- label -->
            <span class="label light-h">
              {{ item.label }}
            </span>
          </ng-template>
        </p-selectButton>
      </div>
      <form [hidden]="currentPage !== '1'" name="UserForm" #UserForm="ngForm" (ngSubmit)="ngOnSubmit(UserForm)">
        <div class="keywest_panel">
          <div class="row">
            <div class="col-xs-12">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.first_name.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  key="trans.name"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.first_name"
                ></app-editTranslation>
              </label>
              <input
                type="text"
                class="form-control"
                name="UserFirstName"
                maxlength="30"
                id="UserFirstName"
                [placeholder]="trans.trans.FirstName.value"
                required
                [(ngModel)]="UserFirstName"
                style="width: 100% !important"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.last_name.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  key="trans.name"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.last_name"
                ></app-editTranslation>
              </label>
              <input
                type="text"
                class="form-control"
                name="UserLastName"
                maxlength="30"
                id="UserLastName"
                [placeholder]="trans.trans.LastName.value"
                [(ngModel)]="UserLastName"
                style="width: 100% !important"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.role.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  key="trans.name"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.role"
                ></app-editTranslation>
              </label>
              <p-dropdown
                appendTo="body"
                [panelStyleClass]="'dropdown-item-font'"
                class="keywest_dropdown dropdown-theme"
                [options]="userRoles"
                [(ngModel)]="userrole"
                name="userrole"
                [placeholder]="trans.trans.select_user.value"
                [optionLabel]="'name'"
                dataKey="id"
                [required]="true"
              ></p-dropdown>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.email_lowercase.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  key="trans.name"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.email_lowercase"
                ></app-editTranslation>
              </label>
              <input
                type="email"
                class="form-control"
                name="userEmail"
                id="userEmail"
                [placeholder]="trans.trans.userEmail.value"
                required
                [(ngModel)]="userEmail"
                style="width: 100% !important"
              />
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.selectGroup.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  key="trans.name"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.selectGroup"
                ></app-editTranslation>
              </label>
              <p-multiSelect
                [panelStyleClass]="'dropdown-item-font'"
                class="keywest_dropdown dropdown-theme"
                [options]="groups"
                name="userGroups"
                id="userGroups"
                [filter]="true"
                filterBy="name"
                [(ngModel)]="selectedGroups"
                [placeholder]="trans.trans.selectGroup.value"
                optionLabel="name"
                appendTo="body"
              ></p-multiSelect>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.manager_email.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  key="trans.name"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.manager_email"
                ></app-editTranslation>
              </label>
              <input
                type="email"
                class="form-control"
                name="managerEmail"
                id="managerEmail"
                [placeholder]="trans.trans.manager_email.value"
                [(ngModel)]="managerEmail"
                style="width: 100% !important"
              />
            </div>
          </div>

          <div class="row" style="margin-top: 10px">
            <div class="col-xs-12">
              <label for="sso_only" class="sidebar_label" style="margin-left: 10px">{{ trans.trans.disableDirectLogin.value }}</label>
              <p-inputSwitch type="checkbox" name="ssoOnly" id="ssoOnly" [(ngModel)]="ssoOnly"></p-inputSwitch>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <label for="sendEmail" class="sidebar_label" style="margin-left: 10px">{{ trans.trans.sendEmail.value }}</label>
              <p-inputSwitch type="checkbox" name="sendEmail" id="sendEmail" [(ngModel)]="sendEmail"></p-inputSwitch>
            </div>
          </div>

          <div class="row">
            <div class="col-xs-12">
              <label for="name" class="sidebar_label"
                >{{ trans.trans.selectPrivilege.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  key="trans.name"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.selectPrivilege"
                ></app-editTranslation>
              </label>
              <p-multiSelect
                [panelStyleClass]="'multi-select-item-font'"
                class="keywest_dropdown multi-select-theme"
                [options]="userPrivilegesList"
                [(ngModel)]="selectedPrivil"
                name="userPrivilegesList"
                placeholder="Select Privilege"
              ></p-multiSelect>
            </div>
          </div>

          <div *ngIf="userProfileDisplaySettingsFf" class="mt-1">
            <!-- Language Type -->
            <div id="language-type-row" class="row mb-1">
              <div class="col-xs-12">
                <label>{{trans.trans.systemLanguage.value}}</label>
                <select name="languageType" id="languageType" type="text" class="form-control profile-dropdown" [(ngModel)]="languageType" required="userProfileDisplaySettingsFf">
                <option  [ngValue]="languageType.id" *ngFor="let languageType of languageTypes">
                    <span >{{getFlag(languageType.id)}}</span>
                    <span class="profile-drop-text">{{ languageType.name }}</span>
                </option>
            </select>
              </div>
            </div>
            <!-- Currency Type -->
            <div id="currency-type-row" class="row mb-1">
              <div class="col-xs-12">
                <label> {{trans.trans.currenyType.value}}</label>
                <select name="currencyType" id="currencyType" type="text" class="form-control profile-dropdown"
                [(ngModel)]="currencyType" required="userProfileDisplaySettingsFf">
                <option [ngValue]="currencyType.id" *ngFor="let currencyType of currencyTypes">
                    <span class="profile-drop-text">{{ currencyType.name }}</span>
                </option>
            </select>
              </div>
            </div>
            <!-- Number format -->
            <div id="number-format-row" class="row mb-1">
              <div class="col-xs-12">
                <label>{{trans.trans.numberFormat.value}}</label>
                <select name="numberFormat" name="numberFormat" id="numberFormat" type="text" class="form-control profile-dropdown"
                [(ngModel)]="numberFormat" required="userProfileDisplaySettingsFf">
                <option [ngValue]="numberFormat.id" *ngFor="let numberFormat of numberFormats">
                    <span class="profile-drop-text">{{ numberFormat.name }}</span>
                </option>
                </select>
              </div>
            </div>
          </div>


        </div>
        <div class="btn_container">
          <div class="col-md-12">
            <button type="submit" class="btn btn_customizable pull-right" [disabled]="UserForm.invalid || !UserForm.dirty">{{ trans.trans.saveAndContinue.value }}</button>
            <button type="button" (click)="ngOnSubmit(UserForm, true)" class="btn cancel-confirm pull-right marg-h" [disabled]="UserForm.invalid || !UserForm.dirty">
              {{ trans.trans.saveAndClose.value }}
            </button>
            <app-are-you-sure
              style="margin-right: 0"
              matTooltipPosition="above"
              [message]="trans.trans.areYouSure.value"
              class="btn cancel-confirm pull-right"
              icon=""
              [text]="trans.trans.cancel.value"
              (callbackYes)="cancelEdit()"
            ></app-are-you-sure>
          </div>
        </div>
      </form>
      <div *ngIf="currentPage === '2' && currentUserID">
        <div class="row" style="overflow-y: hidden; height: calc(100vh - 22.5rem)">
          <app-workflow-config-table [userId]="currentUserID" [trans]="trans"></app-workflow-config-table>
        </div>
        <div class="btn_container">
          <div class="col-md-12">
            <button type="button" class="btn btn_customizable pull-right marg-h" (click)="toggleEditUser = false">{{ trans.trans.close.value }}</button>
            <button (click)="currentPage = '1'; onUserformShow()" type="button" class="btn cancel-confirm pull-right">{{ trans.trans.back.value }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
<p-sidebar [(visible)]="toggleReassign" position="right" styleClass="keywest_sidebar p-sidebar-lg">
  <form name="ReassignForm" #ReassignForm="ngForm" (ngSubmit)="deleteUserAndReassign()">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">{{ trans.trans.reassignValueProps.value }}</div>
      <div class="sidebar_container">
        <div class="keywest_panel">
          <div class="row">
            <div class="col-xs-12">
              <label class="sidebar_label">{{ trans.trans.reassignWarning.value }}</label>
              <p-dropdown
                appendTo="body"
                name="ReassignUserID"
                class="keywest_dropdown"
                [options]="usersGridData"
                [(ngModel)]="reassignUserID"
                optionLabel="name"
                optionValue="id"
                [filter]="true"
              ></p-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="btn_container">
      <div class="row">
        <div class="col-md-12">
          <button type="submit" class="btn btn_customizable pull-right">{{ trans.trans.save.value }}</button>
        </div>
      </div>
    </div>
  </form>
</p-sidebar>
