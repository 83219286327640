import { throwError as observableThrowError, Observable, Subject, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { NotificationService } from '@services/notification.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { heapReset } from 'app/analytics-scripts/heap-scripts';
import { StyleService } from 'app/style.service';
import { FaIconList } from '@shared/models/icons.models';

interface RGBObject {
  r: number;
  g: number;
  b: number;
}

interface HSLObject {
  h: number;
  s: string;
  l: string;
}

@Injectable()
export class CommonService {
  public notifyFeatureChange = new Subject();
  public notifyLoggedOut = new Subject();
  public callbackVPCreateSave = new Subject<{ [klass: string]: any }>();
  public refreshVPGroupDashboard = new Subject();
  public notifyEditTranslations = new Subject<boolean>();
  public notifyChangeLanguage = new Subject<string>();
  public notifyNavigationToggle = new Subject<boolean>();
  public notifyNavToggle = new Subject<boolean>();
  public notifyChangeShowTranslate = new Subject();
  public notifyEditTranslation$ = new BehaviorSubject<boolean>(false);
  setTranslateEdit$ = new Subject();

  serviceUrl: string;
  image_url: string;
  account_Logo: string;
  account_Icon: string;
  asset_generator: string;
  loader_url: string;
  public isDev$ = false;
  public subdomain = '';
  showEditTranslations = false;
  public calcEnv: string;

  public contextualHelp = new Subject<string>();

  chart_colors = ['#00A8E7', '#F35B68', '#4ED8DA', '#F0C807', '#1FBF55'];

  constructor(
    private http: HttpClient,
    private styleService: StyleService,
    private notificationService: NotificationService,
    public translate: TranslateService,
    private router: Router
  ) {
    this.serviceUrl = this.getHostName();
    this.image_url = 'https://media.value-cloud.com/';
    this.styleService.style2022.subscribe((using2022Style) => {
      const theOnlyLogo = '@assets/images/xDLlogo.png';
      this.account_Logo = using2022Style ? theOnlyLogo : theOnlyLogo;
      this.account_Icon = using2022Style ? theOnlyLogo : theOnlyLogo;
    });

    this.loader_url = this.image_url + '/images/jamaica/reload.gif';

    this.setTranslateEdit$.subscribe(() => {
      this.showEditTranslations = !this.showEditTranslations;
      this.notifyEditTranslation$.next(this.showEditTranslations);
    });
  }

  public getSACName(): string {
    const hostname = window.location.hostname;
    let url = '';
    switch (hostname) {
      case 'jamaica.value-cloud.com':
      case 'www.value-cloud.com':
        url = 'https://calculator.value-cloud.com/';
        break;
      case 'www.value-execution.xfactor.io':
        url = 'https://calculator.value-execution.xfactor.io/';
        break;
      case 'stage.value-cloud.com':
        url = 'https://calculator-stage.value-cloud.com/';
        break;
      case 'stage.value-execution.xfactor.io':
        url = 'https://calculator-stage.value-execution.xfactor.io/';
        break;
      default:
        url = 'https://sac-dev.value-cloud.com/';
        break;
    }
    return url;
  }

  public getHostName(): string {
    let url = '';
    if (localStorage.getItem('servicesURL')) {
      if ((this.isDev$ = true)) {
        return localStorage.getItem('servicesURL');
      }
    } else {
      const hostname = window.location.hostname;
      switch (hostname) {
        case 'jamaica.value-cloud.com':
          this.subdomain = 'jamaica';
          this.calcEnv = '';
          url = 'https://jamaicaservices.value-cloud.com/';
          break;
        case 'value-cloud.com':
          this.subdomain = '';
          url = 'https://services.value-cloud.com/';
          break;
        case 'www.value-cloud.com':
          this.subdomain = 'www';
          url = 'https://services.value-cloud.com/';
          break;
        case 'value-execution.xfactor.io':
          this.subdomain = '';
          url = 'https://services.value-execution.xfactor.io/';
          break;
        case 'www.value-execution.xfactor.io':
          this.subdomain = 'www';
          url = 'https://services.value-execution.xfactor.io/';
          break;
        case 'procore.value-cloud.com':
          this.subdomain = 'procore';
          url = 'https://services.value-cloud.com/';
          break;
        case 'stage.value-execution.xfactor.io':
          this.subdomain = 'stage';
          this.calcEnv = 'stage';
          url = 'https://services-stage.value-execution.xfactor.io/';
          break;
        case 'stage.value-cloud.com':
          this.subdomain = 'stage';
          this.calcEnv = 'stage';
          url = 'https://services-stage.value-cloud.com/';
          break;
        case 'localhost':
          this.isDev$ = true;
          this.calcEnv = 'dev';
          this.subdomain = 'localhost';
          url = 'https://develop.api.value-cloud.com/';
          break;
        case 'develop.ui.value-cloud.com':
          this.isDev$ = true;
          this.calcEnv = 'dev';
          url = 'https://develop.api.value-cloud.com/';
          break;
        case 'develop.ui.value-execution.xfactor.io':
          this.isDev$ = true;
          this.calcEnv = 'dev';
          url = 'https://develop.api.value-execution.xfactor.io/';
          break;
        case 'en-1622.ui.value-cloud.com':
          this.isDev$ = true;
          this.subdomain = 'en-1622';
          this.calcEnv = 'dev';
          url = 'https://en-1622.api.value-cloud.com/';
          break;
        case 'en-1622.stage.value-cloud.com':
          this.subdomain = 'en-1622';
          this.calcEnv = 'stage';
          url = 'https://en-1622.services-stage.value-cloud.com/';
          break;
        default:
          this.isDev$ = true;
          this.subdomain = 'develop';

          url = 'https://develop.api.value-cloud.com/';
          break;
      }

      return url;
    }
  }

  public getAssetGenerator(): string {
    let filename = '';

    const whichAssetGen = sessionStorage.getItem('new_asset_gen');

    if (whichAssetGen == '1') {
      filename = 'asset_generator_v2.php';
    } else {
      filename = 'asset_generator.php';
    }

    return filename;
  }

  public getFAIconList(): Observable<FaIconList> {
    return this.http.get<FaIconList>('assets/fa-icons.json');
  }

  public getAssetUrl(): string {
    let url = '';
    let hostname: string = window.location.hostname;

    if (hostname.startsWith('sup') || hostname.startsWith('vcx') || hostname.includes('.ui.value-cloud')) {
      return 'https://develop-media.value-cloud.com/';
    }

    switch (hostname) {
      case 'jamaica.value-cloud.com':
        url = 'https://media.value-cloud.com/';
        break;
      case 'stage.value-cloud.com':
        url = 'https://media-stage.value-cloud.com/';
        break;
      case 'stage.value-execution.xfactor.io':
        url = 'https://media-stage.value-execution.xfactor.io/';
        break;
      case 'value-execution.xfactor.io':
        url = 'https://media.value-execution.xfactor.io/';
        break;
      case 'jamaicav2.ui.value-cloud.com':
      case 'develop.ui.value-cloud.com':
        url = 'https://develop-media.value-cloud.com/';
        break;
      case 'develop.ui.value-execution.xfactor.io':
        url = 'https://develop-media.value-execution.xfactor.io/';
        break;
      case 'localhost':
        url = 'https://develop-media.value-cloud.com/';
        break;
      case 'en-1622.stage.value-cloud.com':
        url = 'https://en-1622.media-stage.value-cloud.com/';
        break;
      case 'dvo-995.prd.value-cloud.com':
        url = 'https://dvo-995.media.value-cloud.com/';
        break;
      default:
        url = 'https://media.value-cloud.com/';
        break;
      
    }
    return url;
  }

  public getMediaServerName(): string {
    let url = '';

    const hostname = window.location.hostname;
    //
    switch (hostname) {
      case 'value-cloud.com':
      case 'jamaica.value-cloud.com':
        url = 'https://media.value-cloud.com/';
        break;
      case 'value-execution.xfactor.io':
        url = 'https://media.value-execution.xfactor.io/';
        break;

      case 'localhost':
        url = 'https://develop-media.value-cloud.com/';
        break;

      case 'develop.ui.value-cloud.com':
        url = 'https://develop-media.value-cloud.com/';
        break;
      case 'develop.ui.value-execution.xfactor.io':
        url = 'https://develop-media.value-execution.xfactor.io/';
        break;

      case 'en-1622.ui.value-cloud.com':
        url = 'https://en-1622.media.value-cloud.com/';
        break;

      case 'stage.value-execution.xfactor.io':
      case 'stage-2.value-execution.xfactor.io':
        url = 'https://media-stage.value-execution.xfactor.io/';
        break;
      case 'stage.value-cloud.com':
      case 'stage-2.value-cloud.com':
        url = 'https://media-stage.value-cloud.com/';
        break;
    }
    return url;
  }

  public getCreateVPRoute(incoming?: string): string {
    const feature31 = this.checkFeature('31');
    const feature34 = this.checkFeature('34');
    const feature73 = this.checkFeature('73');
    const feature110 = this.checkFeature('110');
    const priv8 = this.checkPrivilege('8');

    if (feature73 && priv8 && incoming !== 'cxo') {
      return '/cxo';
    }

    const rid = sessionStorage.getItem('rid');

    if (!feature31 && !feature34) {
      if (incoming == 'valueprop') {
        return '/addvalueprop';
      }
      if (incoming == 'valueposition') {
        return '/addValuePosition';
      }
      if (!incoming) {
        return '/addvalueprop';
      }
    }
    if (feature31) {
      return '/addValuePositionV2';
    }
    if (feature34) {
      if (rid === '5' && feature110) {
        if (incoming == 'valueprop') {
          return '/addvalueprop';
        }
        if (incoming == 'valueposition') {
          return '/addValuePosition';
        }
        if (!incoming) {
          return '/addvalueprop';
        }
      }
      return './repv2';
    }
  }

  public getLinkVPRoute(incoming: string, id: string, step: number): string {
    const feature34 = this.checkFeature('34');
    const feature15 = this.checkFeature('15');
    const feature31 = this.checkFeature('31');
    const feature56 = this.checkFeature('56');
    const feature110 = this.checkFeature('110');

    const rid = sessionStorage.getItem('rid');

    if (feature34 && incoming !== 'groupv1') {
      if (rid == '5' && feature110) {
        return '/valueprop/' + id;
      }
      return step ? '/repv2/' + id + '/' + step : '/repv2/' + id;
    } else {
      if (incoming == 'valueprop') {
        return '/valueprop/' + id;
      }
      if (incoming == 'valueposition') {
        return '/valueposition/' + id;
      }
      if (incoming == 'groupv1') {
        if (feature31) {
          return '/mastervalueprop/' + id + '/group';
        }
        if (feature15) {
          return '/mastervalueprop/' + id + '/group';
        }
        if (feature56) {
          return '/vpg/' + id;
        }
      }
    }
  }

  public delay(ms: number): Promise<NodeJS.Timer> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public getServiceUrl(): string {
    return this.serviceUrl;
  }
  public getImageUrl(): string {
    return this.image_url;
  }

  public getAccountLogoUrl(): string {
    return this.account_Logo;
  }

  public getAccountIconUrl(): string {
    return this.account_Icon;
  }

  public getLoaderImageUrl(): string {
    return this.loader_url;
  }

  public mergeObject(trans: { [klass: string]: any }, res: object): any {
    Object.keys(res).forEach((elem) => {
      if (trans.hasOwnProperty(elem)) {
        trans[elem] = res[elem];
      }
    });

    Object.keys(trans).forEach((elem) => {
      if (!trans[elem].hasOwnProperty('value')) {
        trans[elem] = { value: trans[elem] };
      }
    });
    return trans;
  }

  public getChartColors(): string[] {
    const localChartColors = localStorage.getItem('chart_colors');
    const sessionColors = sessionStorage.getItem('chart_colors');
    const chartColors = sessionColors ? sessionColors : localChartColors;
    if (chartColors !== 'undefined') {
      const tmp = JSON.parse(chartColors);
      if (tmp.length) {
        this.chart_colors = tmp;
      }
    }
    return this.chart_colors;
  }

  private configHeaders(
    ContentType?: string,
    key?: string
  ): {
    options: {
      headers: HttpHeaders;
    };
  } {
    let headers = new HttpHeaders();

    const userId = sessionStorage.getItem('uid');
    const token = sessionStorage.getItem('vcu');

    if (userId) {
      headers = headers.append('User-Id', userId);
    }
    if (token) {
      headers = headers.append('token', token);
    }
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate');
    let contentType = ContentType ? ContentType : 'application/json';

    // If type is multipart/form-data, don't explicitly set the contentType.
    // Have Angular do it automatically instead based on the content of the payload which is FormData
    // This is to avoid issues with the payload boundry not being set for multipart/form-data
    if (contentType !== 'multipart/form-data') {
      headers = headers.append('Content-Type', contentType);
    }

    if (key) {
      headers = headers.append('public_key', key);
    }

    return { options: { headers } };
  }

  private configHeadersKey(
    key: string,
    hash?: string
  ): {
    options: {
      headers: HttpHeaders;
    };
  } {
    let headers = new HttpHeaders();
    headers = headers.append('public_key', key);
    if (hash) {
      headers = headers.append('hash', hash);
    }

    return { options: { headers } };
  }

  public catchError(err: { [klass: string]: any }): unknown[] {
    let isLogout = false;

    if (err._body == '{"result":"Token mismatch."}' || err._body == '{"result":"Unable to authenticate user."}') {
      let isLogout = true;
      this.notificationService.error(err.json().result, isLogout);
      sessionStorage.clear();
      localStorage.clear();
      heapReset();
      localStorage.removeItem('AnalyticsSession');
      this.router.navigate(['/login']);
      return;
    }
    let out: unknown[] = [];

    switch (err.status) {
      case 400:
        return out;

      case 403:
        this.router.navigate(['/dashboard']);
        this.notificationService.error(err.result, isLogout);
        break;

      case 404:
        return out;

      case 406:
        this.notificationService.serverError(err.error.result, isLogout);
        break;

      case 409:
        this.notificationService.serverError(err.statusText, isLogout);
        break;

      case 500:
        this.notificationService.info('Loading please wait', isLogout);
        break;
    }
  }

  public googleTranslate(q: string, target: string): Observable<{ data: { translations: { translatedText: unknown }[] } }> {
    let params = new HttpParams();

    params = params.append('q', q);
    params = params.append('target', target);
    params = params.append('key', 'AIzaSyDdg6DtDCEdjFuOmDUuTz1Gg8NOX0xbotY');

    let url = 'https://translation.googleapis.com/language/translate/v2?key=AIzaSyDdg6DtDCEdjFuOmDUuTz1Gg8NOX0xbotY&q=' + q + '&target=' + target;
    return this.http.get<{ data: { translations: { translatedText: unknown }[] } }>(url);
  }

  public getAPIService(endPointValue: string, key = '', hash = '', tokenNeeded = false): Observable<any> {
    let option: { options: { headers: HttpHeaders } };
    if (key && !tokenNeeded) {
      option = this.configHeadersKey(key, hash);
    } else {
      option = this.configHeaders(null, key);
    }
    return this.http.get(this.serviceUrl + endPointValue, option.options).pipe(
      catchError((err) => {
        this.catchError(err);
        return observableThrowError(err.message || err.json().result || 'Server error');
      })
    );
  }

  public postAPIService(endPointValue: string, payLoad: unknown, ContentType?: string, key: string = null, hash: string = null): Observable<any> {
    let contentType = undefined;
    if (ContentType) {
      contentType = ContentType;
    }
    let option: { options: { headers: HttpHeaders } };

    if (key) {
      option = this.configHeadersKey(key, hash);
    } else {
      option = this.configHeaders(ContentType);
    }

    return this.http.post(this.serviceUrl + endPointValue, payLoad, option.options).pipe(
      catchError((err) => {
        this.catchError(err);
        return observableThrowError(err.message || 'Server error');
      })
    );
  }

  public putAPIService(endPointValue: string, payLoad: unknown, ContentType?: string): Observable<any> {
    let contentType = undefined;
    if (ContentType) {
      contentType = ContentType;
    }
    let option = this.configHeaders(contentType);
    return this.http.put(this.serviceUrl + endPointValue, payLoad, option.options).pipe(
      catchError((err) => {
        this.catchError(err);
        return observableThrowError(err.message || err.json().result || 'Server error');
      })
    );
  }

  public postFileAPIService(endPointValue: string, payLoad: unknown, ContentType?: string): Observable<any> {
    let contentType = undefined;
    if (ContentType) {
      contentType = ContentType;
    }
    let option = this.configHeaders(contentType);
    return this.http.post(this.serviceUrl + endPointValue, payLoad, option.options).pipe(
      catchError((err) => {
        this.catchError(err);
        return observableThrowError(err.message || err.json().result || 'Server error');
      })
    );
  }

  public deleteAPIService(endPointValue: string): Observable<any> {
    const option = this.configHeaders();
    return this.http.delete(this.serviceUrl + endPointValue, option.options).pipe(
      catchError((err) => {
        this.catchError(err);
        return observableThrowError(err?.error?.result?.message || err?.error?.result || err.result || err.message || 'Server error');
      })
    );
  }

  public getHttpNonBaseService(url: string): Observable<any> {
    return this.http.get(url);
  }

  setDatatableOptions(dtOptions: { [klass: string]: any }): { [klass: string]: any } {
    const dtOptionsLan = [
      'general.datatable.show',
      'general.datatable.rows',
      'general.datatable.showing',
      'general.datatable.first',
      'general.datatable.previous',
      'general.datatable.next',
      'general.datatable.last',
      'general.search',
    ];

    dtOptions['language'] = {};
    dtOptions['language']['processing'] = '<img class="loader" src="' + this.getLoaderImageUrl();
    +'" />';

    this.translate.stream(dtOptionsLan).subscribe((res) => {
      dtOptions['language']['lengthMenu'] = res['general.datatable.show'] + ' _MENU_ ' + res['general.datatable.rows'];
      dtOptions['language']['info'] = res['general.datatable.showing'] + ' _START_ to _END_ of _TOTAL_ ' + res['general.datatable.rows'];
      dtOptions['language']['search'] = res['general.search'];
      dtOptions['language']['paginate'] = {
        first: res['general.datatable.first'],
        last: res['general.datatable.last'],
        next: res['general.datatable.next'],
        previous: res['general.datatable.previous'],
      };
    });

    return dtOptions;
  }

  public setXFCSSFile() {
    // Custom for XF
    const head  = document.getElementsByTagName('head')[0];
    const style = document.createElement('link');
    style.id    = 'custom-theme';
    style.rel   = 'stylesheet';
    style.type  = 'text/css';
    style.href  = `assets/xfactor.css`;

    head.appendChild(style);
  }

  setAccountColors(headerHex?: string, buttonHex?: string, buttonTextHex?: string): void {
    const header = headerHex && !Array.isArray(headerHex) ? headerHex : '#013454';
    const headerRGB = this.hexToRgb(header);
    const headerHighlight = this.getHighlight(headerRGB.r, headerRGB.g, headerRGB.b);
    const button = buttonHex || '#06ADAD';
    const buttonRGB = this.hexToRgb(button);
    const buttonRGBA = `rgb(${buttonRGB.r} ${buttonRGB.g} ${buttonRGB.b} / ${0.1 * 100}%)`;
    const buttonHoverRGBA = `rgb(${buttonRGB.r} ${buttonRGB.g} ${buttonRGB.b} / 80%)`;
    const buttonText = buttonTextHex || '#fff';
    const cancelButton = this.hexToRgb('#DDE5EB');
    const cancelButtonHover = `rgb(${cancelButton.r} ${cancelButton.g} ${cancelButton.b} / 80%)`;
    document.documentElement.style.setProperty('--header-color', header);
    document.documentElement.style.setProperty('--header-highlight-color', `hsl(${headerHighlight.h}, ${headerHighlight.s}, ${headerHighlight.l})`);
    document.documentElement.style.setProperty('--button-color', button);
    document.documentElement.style.setProperty('--button-transparent', buttonRGBA);
    document.documentElement.style.setProperty('--button-hover', buttonHoverRGBA);
    document.documentElement.style.setProperty('--button-cancel-hover', cancelButtonHover);
    document.documentElement.style.setProperty('--button-text-color', buttonText);
  }

  private hexToRgb(hex: string): RGBObject {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  private getHighlight(r: number, g: number, b: number): HSLObject {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h: number;
    let s: number;
    let l = (max + min) / 2;

    if (max == min) {
      h = s = 0;
    } else {
      const diff = max - min;
      s = l > 0.5 ? diff / (2 - max - min) : diff / (max + min);
      switch (max) {
        case r:
          h = (g - b) / diff + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / diff + 2;
          break;
        case b:
          h = (r - g) / diff + 4;
          break;
      }
      h /= 6;
    }
    switch (true) {
      case l <= 0.35:
        l = l + 0.05;
        break;
      case l > 0.35 && l < 0.8:
        l = l - 0.05;
        break;
      case l >= 0.8:
        l = l - 0.1;
        break;
    }
    return {
      h: h * 360,
      s: `${s * 100}%`,
      l: `${l * 100}%`,
    };
  }

  public setCSSClassVal(cls: string, param: string, color: string): void {
    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = 'customercolors';

    const entry = cls + ' { ' + param + ': ' + color + ' !important; }';
    style.appendChild(document.createTextNode(entry));

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);
  }

  public checkFeature(id: string | number): boolean {
    const list = sessionStorage.getItem('features');
    const listArr = list ? list.split(',') : [];
    return listArr.includes(id.toString());
  }

  checkFeatures<T extends (string | number)[]>(...ids: T): { [I in keyof T]: boolean } {
    return ids.map((id) => this.checkFeature(id)) as { [I in keyof T]: boolean };
  }

  public checkProduct(id: string | number): boolean {
    let products = JSON.parse(sessionStorage.getItem('products'));
    if (products) {
      let arr = products.find((prod) => prod.product_id == id);
      if (arr !== undefined) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public hasPrivileges(): boolean {
    let userPrivileges = sessionStorage
      .getItem('privileges')
      .split(',')
      .filter((priv) => priv !== '' && priv !== '4' && priv !== '6' && priv !== '7' && priv !== '9' && priv !== '13');
    if (userPrivileges && userPrivileges.length) {
      return true;
    }
    return false;
  }

  public checkPrivilege(id: string | number): boolean {
    let list = sessionStorage.getItem('privileges').split(',');
    if (list && list.indexOf(id.toString()) > -1) {
      return true;
    }
    return false;
  }

  public checkRoleID(id: string): boolean {
    let list = sessionStorage.getItem('rid');
    if (list == id) {
      return true;
    }
    return false;
  }
}
