import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, AfterViewInit } from '@angular/core';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { DiscoveryQuestion } from '@data/services/valueprop/models/discovery-question.model';
import { ValuepropService } from '@data/services/valueprop/valueprop.service';
import { BusinessReviewData } from '@data/services/valuerealization/models/business-review.model';
import { StepperMenuItem } from '@shared/models/stepper-menu.modles';
import { ValueProp } from '@shared/models/value-prop.model';
import { MenuItem } from 'primeng/api';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RSV2ToolbarTranslations } from './toolbar.translation';
import { ConversationWorkflow } from '@data/services/workflow/models/workflow.interface';
import { ConfigurableWorkflowService } from '@data/services/workflow/configurable-workflow.service';

@Component({
  selector: 'app-rsv2-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class RSV2ToolbarComponent implements OnInit, OnDestroy {
  @Input() valueProp: ValueProp;
  @Input() menu: StepperMenuItem[];
  @Input() isValueTracker = false;
  @Input() showTranslate = false;
  @Input() showAllTcoMetrics = false;
  @Input() set step(stepIndex: number) {
    this.repSimpleStep = stepIndex.toString();
    this.setPropActions();
    this.showDiscoveryQuestionsToggle = this.showDiscoveryQuestions();
  }
  @Input() businessReview: BusinessReviewData;
  @Input() noExternalShare = false;
  @Input() businessReviewRequired = false;

  @Output() triggerEditEmit = new EventEmitter();

  showLogs = false;
  repSimpleStep: string;
  ngUnsubscribe = new Subject();
  AssetsMenu: boolean = false;
  menuAssets: any[] = [];
  assetGenerator: string = '';
  userId: any;
  token: any;
  toggleShare: boolean = false;
  toggleCollaboration: boolean = false;
  public showDiscoveryQuestionsToggle = false;
  showDiscoveryAlert: boolean = false;
  toggleDiscoveryQuestions: boolean = false;
  public discoveryQuestions: DiscoveryQuestion[] = [];
  showCollateral = false;

  feature58: boolean = false;
  isValueReaiztionUser: boolean = false;
  promoteToRealization: boolean = false;
  public workflows: ConversationWorkflow[];
  public selectedWorkflow: ConversationWorkflow;

  showClone: boolean = false;
  vpg: boolean = false;
  feature56: boolean = false;
  toggleAddVPG: boolean = false;
  discoveryQuestionsDict = new Map();

  propActions: MenuItem[];
  product4 = false;

  showAssetDeliverables = false;
  finalVersion = this.commonService.checkFeature(35);
  customerCollaboration = this.commonService.checkFeature(149);

  constructor(
    private commonService: CommonService,
    private translationService: TranslationsV2Service,
    public trans: RSV2ToolbarTranslations,
    private valuepropService: ValuepropService,
    private configurableWorkflowService: ConfigurableWorkflowService,
  ) { }

  ngOnInit(): void {
    this.configurableWorkflowService.selectedConversationWorkflow$
    if (sessionStorage.getItem('workflows') && sessionStorage.getItem('workflows') !== 'undefined') {
      this.workflows = JSON.parse(sessionStorage.getItem('workflows'));
      if (this.workflows?.length > 1) {
        this.initWorkflowsForMenu();
      }
    }

    this.discoveryQuestionsDict.set('account', '31');
    this.discoveryQuestionsDict.set('benefits', '38');
    this.discoveryQuestionsDict.set('keyassumptions', '39');
    this.discoveryQuestionsDict.set('costs', '40');
    this.discoveryQuestionsDict.set('results', '37');

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslations.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.getDiscoveryQuestions();

    this.feature56 = this.commonService.checkFeature(56);
    this.feature58 = this.commonService.checkFeature(58);
    this.product4 = this.commonService.checkProduct(4);
    this.isValueReaiztionUser = this.commonService.checkProduct(4);

    const priv7 = this.commonService.checkPrivilege('7');
    if (this.feature56 && priv7) {
      this.vpg = true;
    }
    this.valuepropService.refreshDiscoveryQuestions$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        this.getDiscoveryQuestions();
      }
    });
    this.valuepropService.valueProp.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      if (res) {
        this.valueProp = res;
      }
    });
  }

  ngAfterViewInit() {
    const lightTheme = document.getElementById('custom-theme');

    if (lightTheme !== null) {
      document.getElementById('vp-chevron-down')
        .setAttribute('src', 'assets/images/icons/chevron-down-xf.svg');

      if (this.workflows?.length > 1) {
        document.getElementById('wf-chevron-down')
          .setAttribute('src', 'assets/images/icons/chevron-down-full-xf.svg');
      }
    }
  }

  initWorkflowsForMenu() {
    this.workflows?.forEach(workflow => {
      workflow.label = workflow.name;
      workflow.command = (wf) => (this.onWorkflowChange(wf.item))
      workflow.automationId = "value-execution-add-a-new-value-proposition-workflow-" + workflow.name.toLowerCase().split(" ").join("-")
    });

    this.setSelectedWorkflow();
  }

  setSelectedWorkflow() {
    if (sessionStorage.getItem('selectedConversationWorkflow') && sessionStorage.getItem('selectedConversationWorkflow') !== 'undefined') {
      this.selectedWorkflow = JSON.parse(sessionStorage.getItem('selectedConversationWorkflow'));
    } else {
      this.selectedWorkflow = this.workflows[0];
    }
  }

  getDiscoveryQuestions(): void {
    this.valuepropService
      .getDiscoveryQuestions(this.valueProp.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response: { result: DiscoveryQuestion[] }) => {
        this.discoveryQuestions = response.result;
        this.showDiscoveryQuestionsToggle = this.showDiscoveryQuestions();
        this.setPropActions();
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
        this.setPropActions();
      });
  }

  showDiscoveryQuestions(): boolean {
    if (!this.menu || !this.menu[+this.repSimpleStep]) {
      return false;
    }
    const stepId = this.discoveryQuestionsDict.get(this.menu[+this.repSimpleStep].viewTop);
    const questions = this.discoveryQuestions.filter((question) => {
      const sectionStep = question.sections.filter((section) => section.id === stepId);
      return sectionStep.length > 0;
    });

    this.showDiscoveryAlert = false;
    questions.forEach(question => {
      if (question.is_placeholder_text === true) {
        this.showDiscoveryAlert = true;
      }
    });

    return questions.length > 0;
  }
  setPropActions() {
    this.propActions = [
      {
        label: this.trans.trans.download.value,
        command: () => (this.AssetsMenu = !this.AssetsMenu),
        automationId: "value-execution-add-a-new-value-proposition-value-prop-actions-menu-download"
      }
    ];

    if (this.customerCollaboration) {
      this.propActions.push(
        {
          label: this.trans.trans.collaborateInternally.value,
          command: () => (this.toggleShare = !this.toggleShare),
          automationId: "value-execution-add-a-new-value-proposition-value-prop-actions-menu-collaborate-internally"
        },
        {
          label: this.trans.trans.externalCollaboration.value,
          command: () => (this.toggleCollaboration = !this.toggleCollaboration),
          automationId: "value-execution-add-a-new-value-proposition-value-prop-actions-menu-collaborate-with-customer"
        }
      );
    } else {
      this.propActions.push(
        {
          label: this.trans.trans.share.value,
          command: () => (this.toggleShare = !this.toggleShare),
          automationId: "value-execution-add-a-new-value-proposition-value-prop-actions-menu-share"
        }
      );
    }

    this.propActions.push(
      {
        label: this.trans.trans.clone.value,
        icon: 'prop-icon icon-docs',
        command: () => (this.showClone = !this.showClone),
        automationId: "value-execution-add-a-new-value-proposition-value-prop-actions-menu-clone"
      },
      {
        label: this.trans.trans.addCollateral.value,
        icon: 'fa fa-file',
        command: () => (this.showCollateral = !this.showCollateral),
        automationId: "value-execution-add-a-new-value-proposition-value-prop-actions-menu-add-collateral"
      },
      {
        label: this.trans.trans.logs.value,
        icon: 'fa fa-sticky-note',
        command: () => (this.showLogs = !this.showLogs),
        automationId: "value-execution-add-a-new-value-proposition-value-prop-actions-menu-logs"
      },
    );

    if (this.finalVersion) {
      this.propActions.splice(4, 0, {
        label: this.trans.trans.finalCustomerDeliverables.value,
        icon: 'fa fa-archive',
        command: () => (this.showAssetDeliverables = !this.showAssetDeliverables),
        automationId: "value-execution-add-a-new-value-proposition-value-prop-actions-menu-final-customer-deliverables"
      });
    }

    if (this.vpg) {
      this.propActions.splice(3, 0, {
        label: this.trans.trans.vpg.value,
        icon: 'fa fa-plus',
        command: () => (this.toggleAddVPG = !this.toggleAddVPG),
        automationId: "value-execution-add-a-new-value-proposition-value-prop-actions-menu-add-to-group",
      });
    }
  }

  triggerEdit() {
    this.triggerEditEmit.emit();
  }

  private valuePropInsert(array: MenuItem[], index: number, ...elementsArray: MenuItem[]): void {
    array.splice(index, 0, ...elementsArray);
  }

  public onWorkflowChange(wf: ConversationWorkflow) {
    this.selectedWorkflow = wf;
    this.configurableWorkflowService.selectedConversationWorkflow$.next(wf);
  }
}
