import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm } from '@angular/forms';
import { AccountService } from '@data/services/account/account.service';
import { AdminUserGroupsService } from '@data/services/account/user_groups.service';
import { CommonService } from '@data/services/common/common.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { UserService } from '@data/services/user/user.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '@services/notification.service';
import { UserGroup } from '@shared/models/account-groups.models';
import { BasicInfo } from '@shared/models/basic-info.model';
import { Owner } from '@shared/models/owner.model';
import { Breadcrumbs } from '@shared_components/breadcrumbs/breadcrumbs/breadcrumbs.model';
import { StyleService } from 'app/style.service';
import { MenuItem, SelectItem } from 'primeng/api';
import { Subject, Subscription, zip } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';
import { UsersProfileTranslation } from './users.translation';
import { AppImplementation } from 'app/app/app.implementation';
import { CountryFlag, CountryFlagService } from '@data/services/country-flag/country-flag.service';
import { LanguageType } from '@shared/models/user-info.model';
import { Currency, NumberFormat } from '@data/services/valueprop/models/situation-opportunity.model';
import { FactsService } from '@data/services/facts/facts.service';
interface tabOption {
  value: string;
  label: string;
  inactive: boolean;
}
@Component({
  selector: 'app-users',
  styleUrls: ['./users.component.scss'],
  templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit, OnDestroy {
  fullImagePath: string;
  usersGridData: Owner[] = [];
  usersGridDataForDropdown: SelectItem[];
  loadManagersTable = false;
  page: number;
  pageLimit = 10;
  order = 'desc';
  userData: Owner[];
  dtTrigger = new Subject();
  sortBy = 'created';
  hideuserlist = true;
  showActionLoader = false;
  userRoles: BasicInfo[];
  deleteUserId: string;
  removecustomeronly: number;
  modalReference: NgbModalRef;
  UserFirstName: string;
  UserMiddleInitial: string;
  UserLastName: string;
  userrole: BasicInfo;
  userEmail: string;
  managerEmail: string;
  ssoOnly = false;
  sendEmail = false;
  editMode: boolean;
  currentUserID: number;
  userResult = true;
  userNoResult = false;
  userPrivilegesList: Array<Object>;
  selectedPrivil: { label?: string; value?: string; id?: string; name?: string }[] = [];
  dropdownSettings: { [klass: string]: any };
  dropdownSettingsReassign: { [klass: string]: any } = {};
  reassignUserID: string;
  value_prop_count: number;
  selectedUser: Owner;
  toggleReassign = false;
  groups: UserGroup[] = [];

  private flagMap: Map<string, CountryFlag>;
  public numberFormats: NumberFormat[] = [];
  public languageTypes: LanguageType[] = [];
  public currencyTypes: Currency[] = [];
  public numberFormat = '';
  public currencyType = '';
  public languageType = '';
  public userProfileDisplaySettingsFf = this.commonService.checkFeature(156);

  @ViewChild('deleteUserModal', { static: true }) deleteModal;

  @ViewChild('reassignAndDeleteUserModal', { static: true }) reassignModal;
  @ViewChild('UserForm') form: NgForm;

  subscriptiongetUsersRoles: Subscription;
  subscriptiongetPrivileges: Subscription;
  subscriptiongetAllUsersList: Subscription;
  subscriptionhasValueProp: Subscription;
  subscriptiondeleteUser: Subscription;
  subscriptiondeleteUserAndReassign: Subscription;
  subscriptionAddNewUser: Subscription;
  subscriptionupdateUser: Subscription;
  account_id: string;
  selectedGroups: UserGroup[] = [];
  showTranslate = false;
  strSearch = '';
  rowLimit: MenuItem[] = [];
  toggleEditUser = false;
  loading = false;
  isUserAdmin = this.commonService.checkPrivilege(5);
  ngUnsubscribe = new Subject();
  breadcrumbKeyValues: Breadcrumbs;

  sidebarTitle: { value: string };

  public currentPage = '1';
  public pageOptions: tabOption[];

  constructor(
    private userService: UserService,
    private notification: NotificationService,
    private accountService: AccountService,
    private translationService: TranslationsV2Service,
    public trans: UsersProfileTranslation,
    private commonService: CommonService,
    private adminUserGroups: AdminUserGroupsService,
    private styleService: StyleService,
    public aI: AppImplementation,
    private FlagService: CountryFlagService,
    private factsService: FactsService,
  ) {
    this.fullImagePath = this.commonService.getLoaderImageUrl();
  }

  ngOnInit() {
    this.account_id = sessionStorage.getItem('aid');
    this.funcBuildPageLimit();

    this.getTranslations();
    this.commonService.notifyChangeLanguage.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.getTranslations();
    });
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.subscriptiongetUsersRoles = this.userService.getUsersRoles().subscribe((result) => {
      this.userRoles = result.result.filter((role) => role.id !== 7);
    });
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select Privilege',
      enableCheckAll: false,
      enableSearchFilter: false,
      labelKey: 'name',
      badgeShowLimit: 'all',
      lazyLoading: false,
    };

    this.dropdownSettingsReassign = {
      singleSelection: true,
      text: 'Select UserName',
      enableSearchFilter: true,
      enableFilterSelectAll: false,
      badgeShowLimit: 'all',
      noDataLabel: 'No Data Found...',
      searchBy: ['name'],
      labelKey: 'name',
      lazyLoading: false,
    };

    this.funcGetGroups();
    this.loadUsersList();
    this.getPrivileges();

    if (this.userProfileDisplaySettingsFf) {
      this.FlagService.countryFlagMap$
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(map => this.flagMap = map);
      this.getUserData();
    }
  }

  private getUserData() {
    zip(...this.getDataCalls())
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data) => {
        this.languageTypes = data[0].result;
        this.currencyTypes = data[1].result;
        this.numberFormats = data[2].result;
      })
  }

  private getDataCalls() {
    return [
      this.userService.getLanguages(),
      this.userService.getCurriences(),
      this.factsService.getNumberFormats(),
    ]
  }

  public getFlag(lang: string): string {
    const flag = this.flagMap.get(lang);
    if (!flag) {
      return '';
    }
    return flag.unicodeIcon;
  }

  public onUserformShow() {
    if (this.form) {
      if (!this.form.invalid && this.currentUserID) {
        // unlock second tab
        this.pageOptions[1].inactive = false;
        this.pageOptions = [...this.pageOptions];
      } else {
        this.pageOptions[1].inactive = true;
        this.pageOptions = [...this.pageOptions];
      }
      this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
        if (!this.form?.invalid && this.currentUserID) {
          // unlock second tab
          this.pageOptions[1].inactive = false;
          this.pageOptions = [...this.pageOptions];
        } else {
          this.pageOptions[1].inactive = true;
          this.pageOptions = [...this.pageOptions];
        }
      });
      this.form.controls.userGroups.valueChanges.pipe(delay(500), takeUntil(this.ngUnsubscribe)).subscribe(() => {
        this.pageOptions[1].inactive = true;
        this.pageOptions = [...this.pageOptions];
      });
    }
  }

  public defaultUserSidebar() {
    this.currentPage = '1';
    this.createPageOptions();
    this.currentUserID = undefined;
  }

  ngOnDestroy(): void {
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
    if (this.subscriptiongetUsersRoles) {
      this.subscriptiongetUsersRoles.unsubscribe();
    }
    if (this.subscriptiongetPrivileges) {
      this.subscriptiongetPrivileges.unsubscribe();
    }
    if (this.subscriptiongetAllUsersList) {
      this.subscriptiongetAllUsersList.unsubscribe();
    }
    if (this.subscriptionhasValueProp) {
      this.subscriptionhasValueProp.unsubscribe();
    }
    if (this.subscriptiondeleteUser) {
      this.subscriptiondeleteUser.unsubscribe();
    }
    if (this.subscriptiondeleteUserAndReassign) {
      this.subscriptiondeleteUserAndReassign.unsubscribe();
    }
    if (this.subscriptionAddNewUser) {
      this.subscriptionAddNewUser.unsubscribe();
    }
    if (this.subscriptionupdateUser) {
      this.subscriptionupdateUser.unsubscribe();
    }

    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  private createPageOptions() {
    this.pageOptions = [
      { value: '1', label: this.trans.trans.basic.value, inactive: false },
      { value: '2', label: this.trans.trans.wfAssignments.value, inactive: this.currentUserID && !this.form.invalid ? false : true },
    ];
  }

  getTranslations() {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);

        this.breadcrumbKeyValues = {
          [this.trans.trans.dashboard.value]: { routerLink: '/dashboard', active: false },
          [this.trans.trans.settings.value]: { routerLink: '/settings', active: false },
          [this.trans.trans.manageusers.value]: { routerLink: '', active: true },
        };
        this.createPageOptions();
        this.sidebarTitle = this.editMode ? this.trans.trans.editUser : this.trans.trans.addUser;
      });
  }

  setRowLimit(num) {
    this.pageLimit = num;
    this.funcBuildPageLimit();
  }

  funcBuildPageLimit() {
    this.rowLimit = [
      {
        label: '10',
        icon: this.pageLimit === 10 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(10);
        },
      },
      {
        label: '25',
        icon: this.pageLimit === 25 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(25);
        },
      },
      {
        label: '50',
        icon: this.pageLimit === 50 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(50);
        },
      },
      {
        label: '100',
        icon: this.pageLimit === 100 ? 'fa fa-check' : null,
        command: () => {
          this.setRowLimit(100);
        },
      },
    ];
  }

  funcGetGroups() {
    this.adminUserGroups.getGroups(this.account_id).subscribe((res) => {
      if (res.result) {
        this.groups = res.result;
      }
    });
  }

  getPrivileges() {
    this.subscriptiongetPrivileges = this.accountService.getPrivileges().subscribe((result) => {
      this.userPrivilegesList = result.result.map((priv) => ({
        label: priv.name,
        value: priv,
      }));
    });
  }

  loadUsersList() {
    this.loading = true;
    this.subscriptiongetAllUsersList = this.userService.getAllUsersList().subscribe((result) => {
      if (result.result.data.length > 0) {
        this.userResult = true;
        this.userNoResult = false;
        this.userData = result.result.data;
        this.usersGridData = this.userData;
        this.usersGridDataForDropdown = this.usersGridData.map((user) => ({
          label: user.name,
          value: user,
        }));

        this.loadManagersTable = true;
        this.dtTrigger.next();
      } else {
        this.userNoResult = true;
        this.userResult = false;
      }
      this.loading = false;
    });
  }

  updateUserForm(user) {
    this.selectedPrivil = [];
    this.selectedGroups = this.groups.filter((grp) => user.account_geo_ids?.includes(+grp.id) && user.geo_names.includes(grp.name));
    this.hideuserlist = false;
    this.UserFirstName = user.first_name;
    this.UserLastName = user.last_name;
    this.userrole = this.userRoles.find((role) => role.id === user.role_type_id);
    this.userEmail = user.email;
    this.managerEmail = user.manager_email;
    this.ssoOnly = Boolean(parseInt(user.sso_only));
    this.currentUserID = parseInt(user.id);
    this.editMode = true;
    this.currencyType = user.currency_type_id;
    this.languageType = user.language_type_id;
    this.numberFormat = user.number_format;
    this.sidebarTitle = this.trans.trans.editUser;
    if (user && user.user_privileges && user.user_privileges.length) {
      this.selectedPrivil = user.user_privileges.map((elem) => {
        if (elem && elem.id) {
          return { id: elem.id, name: elem.name };
        } else {
          return null;
        }
      });
    }
    this.toggleEditUser = !this.toggleEditUser;
  }

  AddUserForm() {
    this.hideuserlist = false;
    this.editMode = false;
    this.UserFirstName = '';
    this.UserLastName = '';
    this.userrole = null;
    this.userEmail = '';
    this.managerEmail = '';
    this.ssoOnly = false;
    this.sendEmail = false;
    this.toggleEditUser = true;
    this.selectedPrivil = [];
    this.selectedGroups = null;
    this.sidebarTitle = this.trans.trans.addUser;
    this.numberFormat = '1'; // US English
    this.currencyType = '144'; // USD
    this.languageType = '1'; // 1,000.00
  }

  hideAddUser() {
    this.hideuserlist = true;
  }

  open(user) {
    this.selectedUser = user;
    this.deleteUserId = user['id'];
    this.reassignUserID = null;
    this.hasValueProp();
  }

  disconectFromCRM(user) {
    this.userService.disconectFromCRM(user.id).subscribe((response) => {
      if (response) {
        this.loadUsersList();
      }
    });
  }

  resetUserPassword(user) {
    this.userService.resetUsersPassword(user).subscribe((response) => {
      if (response.result.success) {
        this.loadUsersList();
      }
    });
  }

  hasValueProp() {
    this.subscriptionhasValueProp = this.userService.hasValueProp(this.deleteUserId).subscribe((response) => {
      if (response.result && response.result.success) {
        this.openModal(response.result.value_prop_count);
      }
    });
  }

  openModal(value_prop_count) {
    value_prop_count = parseInt(value_prop_count, 10);
    if (value_prop_count > 0) {
      this.toggleReassign = true;
      this.value_prop_count = value_prop_count;
    } else {
      this.deleteUser(this.deleteUserId);
    }
  }

  deleteUser(id) {
    this.showActionLoader = true;
    this.subscriptiondeleteUser = this.userService.deleteUser(id).subscribe((result) => {
      this.notification.success(result.result, false);
      this.showActionLoader = false;
      this.loadUsersList();
    });
  }

  deleteUserAndReassign() {
    this.showActionLoader = true;
    this.subscriptiondeleteUserAndReassign = this.userService.deleteUserAndReassign(this.deleteUserId, this.reassignUserID).subscribe((response) => {
      if (response.result && response.result.success) {
        this.notification.success(response.result.message, false);
        this.loadUsersList();
      }
      this.toggleReassign = false;
    });
  }

  ngOnSubmit(form: NgForm, close = false) {
    if (form.valid) {
      this.showActionLoader = true;
    }
    const accId = sessionStorage.getItem('aid');
    const formdata = {
      username: form.value.UserFirstName + form.value.UserLastName, // added until we can fully remove from api
      first_name: form.value.UserFirstName,
      last_name: form.value.UserLastName,
      email: form.value.userEmail,
      role_type_id: form.value.userrole.id,
      manager_email: form.value.managerEmail,
      language_type_id: form.value.languageType,
      number_format: form.value.numberFormat,
      currency_type_id: form.value.currencyType,
      sso_only: form.value.ssoOnly,
      nomail: form.value.sendEmail ? 0 : 1,
      account_geo_ids:
        this.selectedGroups && this.selectedGroups.length > 0
          ? this.selectedGroups.map((group) => {
              return +group.id;
            })
          : '',
    };

    if (!this.editMode) {
      formdata['email'] = form.value.userEmail;
      formdata['first_login'] = 1;
      formdata['temp_password'] = 1;
      formdata['accepted_terms'] = 0;
      formdata['user_type_id'] = 1;
      formdata['account_id'] = accId;

      const selectedPrivilTemp = this.selectedPrivil.map((item) => {
        if (item && item.id) {
          return item['id'];
        }
      });
      formdata['privilege'] = selectedPrivilTemp.join(',');

      this.subscriptionAddNewUser = this.userService.AddNewUser(formdata).subscribe(
        (result) => {
          this.showActionLoader = false;

          if (result.result.success) {
            this.notification.success('User created successfully', false);
            if (close) {
              this.hideuserlist = true;
              this.loadUsersList();
              this.toggleEditUser = false;
            } else {
              this.currentUserID = result.result.user_id;
              this.currentPage = '2';
              this.pageOptions[1].inactive = false;
              this.pageOptions = [...this.pageOptions];
              this.form.form.markAsPristine();
              this.loadUsersList();
            }
          } else {
            this.notification.error(result.result.errors.reason, false);
          }
        },
        () => {
          this.showActionLoader = false;
        }
      );
    } else {
      const selectedPrivilTemp = this.selectedPrivil.map((item) => {
        if (item && item.id) {
          return item['id'];
        }
      });
      formdata['privilege'] = selectedPrivilTemp.join(',');
      this.subscriptionupdateUser = this.userService.updateUser(this.currentUserID, formdata).subscribe(
        () => {
          this.showActionLoader = false;
          this.hideuserlist = true;
          const isLoggedUser = this.currentUserID === parseInt(sessionStorage.getItem('uid'), 10);
          if (isLoggedUser && sessionStorage.getItem('rid') !== formdata.role_type_id) {
            sessionStorage.setItem('rid', formdata.role_type_id);
            this.styleService.updateDashboard();
          }
          if (isLoggedUser) {
            sessionStorage.setItem('privileges', formdata['privilege']);
            sessionStorage.setItem('active_privileges', this.selectedPrivil.map((item) => item.name).join(','));
          }
          this.loadUsersList();

          this.notification.success('User has been updated successfully', false);
          if (close) {
            this.hideuserlist = true;
            this.loadUsersList();
            this.toggleEditUser = false;
          } else {
            this.currentPage = '2';
            this.pageOptions[1].inactive = false;
            this.pageOptions = [...this.pageOptions];
            this.form.form.markAsPristine();
            this.loadUsersList();
          }
        },
        () => {
          this.showActionLoader = false;
        }
      );
    }
  }

  cancelEdit(): void {
    this.toggleEditUser = false;
  }
}
