import { ViewChild, HostListener, ElementRef, Injectable, OnDestroy, Directive } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationComponent } from '../_directives/notification/notification.component';
import { AlertService } from '../_services/alert.service';
import { ApiServiceService } from '../api-service.service';
import { AccountService } from '../data/services/account/account.service';
import { CommonService } from '../data/services/common/common.service';
import { UserService } from '../data/services/user/user.service';
import { SpinnerService } from '@services/spinner.service';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalForgotPasswordComponent } from '../forgot-password/forgot-password.modal';
import { EditDirectiveService } from '@shared_components/editTranslation/edit.service';
import { KeywestTranslations } from './views/keywest/keywest.translation';
import { SFDCService } from '@data/services/sfdc/sfdc.service';
import { heapReset } from 'app/analytics-scripts/heap-scripts';
import { setupAnalytics } from 'app/analytics-scripts/analytics';
import { StyleService } from 'app/style.service';
import { UserInfo } from '@shared/models/user-info.model';
import { loadParlor } from '../analytics-scripts/parlor';
import { accountDefaultColors } from '@shared/utils';
import { takeUntil, switchMap, take } from 'rxjs/operators';
import { ErrorMessagesService } from '@services/error-messages.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { SolutionService } from '@data/services/solution/solution.service';

declare global {
  interface Window {
    Parlor: any;
    parlorSettings: any;
  }
}
@Directive()
@Injectable()
export class AppImplementation implements OnDestroy {
  servicesUrl: string = '';
  showSystemStatus: boolean = false;
  HTTPActivity: boolean;
  @ViewChild('st') st: ElementRef;
  @ViewChild(NotificationComponent, { static: true }) notification: NotificationComponent;
  userData: any;
  isLoggedIn: boolean;
  userID = sessionStorage.getItem('uid');
  userLogo = sessionStorage.getItem('logo');
  isLoggedIn$: boolean;
  isIntake$: boolean;
  profileImagePath: string = 'https://media.value-cloud.com/images/default_user.png';
  userDetails: UserInfo;
  tokenExpired: boolean = false;
  redirectLogin: boolean = false;
  flagUserLanguage: boolean = false;
  currentColor: string;
  companyLogo: string;
  companyIcon: string;
  isDefaultcompanyLogo$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  contextualHelpList: Array<Object>;
  profilepicdefaultpath: string = 'https://media.value-cloud.com/images/default_user.png';
  crm: any;

  medium_chart_colors: string[] = [
    '#e57373',
    '#f06292',
    '#ba68c8',
    '#9575cd',
    '#7986cb',
    '#64b5f6',
    '#4fc3f7',
    '#4dd0e1',
    '#4db6ac',
    '#81c784',
    '#aed581',
    '#dce775',
    '#fff176',
    '#ffd54f',
    '#ffb74d',
    '#ff8a65',
    '#a1887f',
    '#e0e0e0',
    '#90a4ae',
  ];

  isMobile: boolean = false;

  menuToggle: boolean = true;
  mainCenterClass: string = 'center_180';

  subscriptionRouterEvents: Subscription;
  subscriptionloggedIn: Subscription;
  subscriptionisIntake: Subscription;
  subscriptiongetUser: Subscription;
  subscriptionchangeHeaderColor: Subscription;
  subscriptionprofilePic: Subscription;
  subscriptioncompanyLogo: Subscription;
  subscriptioncompanyIcon: Subscription;
  subscriptiongetContextualHelpList: Subscription;

  ngUnsubscribe = new Subject();
  modalRef: NgbModalRef;
  modalRefForgotPassword: NgbModalRef;
  subscriptionSubscribeLoggedIn: Subscription;
  systemColor: boolean = false;
  darkMode: boolean = false;
  isDev: boolean;

  languages: any[] = [];
  language_type_id: string = '1';
  hash_id: boolean = false;
  system_version: string = '';
  current_version: string = '';
  notify_refresh: boolean = false;

  feature42: boolean = false;
  feature58 = false;

  server: string = '';

  get user(): any {
    return sessionStorage.getItem('username');
  }

  get role(): any {
    return sessionStorage.getItem('role');
  }
  showNavigation: boolean = true;
  
  showNavToggle: boolean = true;

  addRemoveUsers: boolean = true;
  
  toggleSupport: boolean = false;

  strSearch: string = '';

  cuie1: string = '';
  dlke2: string = '';
  sfdcRedirect: string = '';

  supportUrl: string;

  constructor(
    private apiServiceService: ApiServiceService,
    private router: Router,
    public commonService: CommonService,
    private accountService: AccountService,
    private AlertService: AlertService,
    private userService: UserService,
    private spinnerService: SpinnerService,
    private modalService: NgbModal,
    private UserService: UserService,
    private editDirectiveService: EditDirectiveService,
    public trans: KeywestTranslations,
    private sfdcService: SFDCService,
    private styleService: StyleService,
    private errorMessagesService: ErrorMessagesService,
    private translationService: TranslationsV2Service,
    private solutionService: SolutionService
  ) {
    this.commonService.setCSSClassVal('.bg', 'background-image', 'url(../../assets/images/xio-bg.png)');

    this.funcIsMobile();
    if (this.isMobile) {
      this.menuToggle = false;
    }
  }

  setV2() {
    let keywest = localStorage.getItem('keywest');
    if (keywest == 'true') {
      localStorage.setItem('keywest', 'false');
      location.reload();
    } else {
      localStorage.setItem('keywest', 'true');
      location.reload();
    }
  }

  reload() {
    location.reload();
  }

  ngOnInit() {
    this.accountService.supportUrl$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((supportUrl) => {
      if (supportUrl) {
        this.supportUrl = this.prependHttp(supportUrl);
      }
    });

    this.accountService.getSupportUrl();

    if (localStorage.getItem('servicesURL') == 'null') {
      localStorage.removeItem('servicesURL');
      this.commonService.getHostName();
    }

    this.UserService.toggleSupportSidebar.subscribe(() => {
      this.toggleSupport = !this.toggleSupport;
    });

    this.funcSetCustomColors();

    this.AlertService.subscribeLoggedIn().subscribe(() => {
      this.commonService.notifyFeatureChange.next();
      this.funcSetCustomColors();

      this.getLanguages();

      this.language_type_id = sessionStorage.getItem('language_type_id');

      this.setProfileImage(sessionStorage.getItem('uid'));
      this.errorMessagesService.getTranslations();
    });

    this.servicesUrl = this.commonService.getHostName();
    this.isDev = this.commonService.isDev$;
    this.commonService.notifyLoggedOut.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.isLoggedIn = false;
      this.isLoggedIn$ = false;
    });

    this.subscriptiongetUser = this.apiServiceService.getUser$.subscribe((getUser) => {
      this.userData = getUser;
    });

    this.isLoggedInOnRouteChange();
    this.accountService.startExpInterval();

    this.subscriptionchangeHeaderColor = this.apiServiceService.changeHeaderColor.subscribe((color) => {
      this.currentColor = color;
      if (this.systemColor == true) {
        this.setCustomColor(this.currentColor);
      }
    });

    this.subscriptionprofilePic = this.apiServiceService.profilePic.subscribe((res) => {
      this.profileImagePath = res;
    });

    this.subscriptioncompanyLogo = this.apiServiceService.companyLogo.subscribe((res) => {
      if (res) {
        this.companyLogo = res;
      } else {
        const logoStorage = sessionStorage.getItem('logo');
        if (logoStorage) {
          this.companyLogo = logoStorage;
        } else {
          this.companyLogo = this.commonService.getAccountLogoUrl();
          this.isDefaultcompanyLogo$.next(true);
        }
      }
    });

    this.subscriptioncompanyIcon = this.apiServiceService.companyIcon.subscribe((res) => {
      this.companyIcon = res;
    });

    this.commonService.notifyNavigationToggle.subscribe((res) => {
      this.showNavigation = res;
      this.funcSetMainCenterClass();
    });

    if (this.isLoggedIn$ || this.isLoggedIn) {
      this.getLanguages();

      this.checkFeatures();
      this.language_type_id = sessionStorage.getItem('language_type_id');

      let uid = sessionStorage.getItem('uid');

      this.setProfileImage(uid);
      this.errorMessagesService.getTranslations();

      loadParlor();
    }
   
    this.checkForCrms();

    this.subscriptionSubscribeLoggedIn = this.AlertService.subscribeLoggedIn().subscribe((data) => {
      this.hash_id = sessionStorage.getItem('hash_id') ? true : false;
      this.setProfileImage(data.user_id);
      this.errorMessagesService.getTranslations();
      this.modalRef = null;
      this.checkFeatures();
      loadParlor();
    });

    this.AlertService.subscribeShowForgotPassword().subscribe(() => {
      this.openForgotPassword();
    });

    let url = location.pathname;
    if (url.indexOf('valueprop_customer') >= 0) {
      this.crm = true;
    }

    const analytics = sessionStorage.getItem('will_setup_analytics');
    if (!analytics) {
        this.checkCountryForAnalytics();
    }
    if (analytics === 'yes') {
      setupAnalytics();
    }
  }

  ngOnDestroy() {
    if (this.subscriptionRouterEvents) {
      this.subscriptionRouterEvents.unsubscribe();
    }
    if (this.subscriptionloggedIn) {
      this.subscriptionloggedIn.unsubscribe();
    }
    if (this.subscriptionisIntake) {
      this.subscriptionisIntake.unsubscribe();
    }
    if (this.subscriptiongetUser) {
      this.subscriptiongetUser.unsubscribe();
    }
    if (this.subscriptionchangeHeaderColor) {
      this.subscriptionchangeHeaderColor.unsubscribe();
    }
    if (this.subscriptionprofilePic) {
      this.subscriptionprofilePic.unsubscribe();
    }
    if (this.subscriptioncompanyLogo) {
      this.subscriptioncompanyLogo.unsubscribe();
    }
    if (this.subscriptioncompanyIcon) {
      this.subscriptioncompanyIcon.unsubscribe();
    }
    if (this.subscriptiongetContextualHelpList) {
      this.subscriptiongetContextualHelpList.unsubscribe();
    }
    if (this.subscriptionSubscribeLoggedIn) {
      this.subscriptionSubscribeLoggedIn.unsubscribe();
    }
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  // Imported from https://www.npmjs.com/package/prepend-http
  prependHttp(url: string, { https = true } = {}) {
    url = url.trim();
    if (/^\.*\/|^(?!localhost)\w+?:/.test(url)) {
      return url;
    }
    return url.replace(/^(?!(?:\w+?:)?\/\/)/, https ? 'https://' : 'http://');
  }

  SFDCLogin() {
    this.sfdcService.getSFDC(this.cuie1, this.dlke2).subscribe((res) => {
      sessionStorage.setItem('header_color', res.result.header_color);
      sessionStorage.setItem('button_color', res.result.button_color);
      sessionStorage.setItem('button_text_color', res.result.button_text_color);

      sessionStorage.setItem('chart_colors', res.result.enc_chart_colors);

      sessionStorage.setItem('asset_gen_file', res.result.asset_gen_file);
      sessionStorage.setItem('language_type_id', res.result.language_type_id);
      sessionStorage.setItem('account_name', res.result.account_name);
      sessionStorage.setItem('vcu', res.result.token);
      sessionStorage.setItem('uid', res.result.user_id);
      sessionStorage.setItem('aid', res.result.account_id);

      sessionStorage.setItem('rid', res.result.role_type_id);
      sessionStorage.setItem('features', res.result.features);
      this.styleService.updateStyle2022();
      sessionStorage.setItem('privileges', res.result.privileges);
      this.crm = { type: 'SFDC' };
      let param = this.sfdcRedirect.split('/');

      this.crm.redirect = param[1];

      this.crm.value_prop_id = param[2];
      sessionStorage.setItem('crm', JSON.stringify(this.crm));
      this.showSystemStatus = false;
      this.router.navigate(['/' + this.sfdcRedirect]).then(() => {
        window.location.reload();
      });
    });
  }

  funcSetCustomColors() {
    const colr = sessionStorage.getItem('button_color');
    document.querySelector('body').style.setProperty('$global-button-color', colr);
  }
  funcIsMobile() {
    this.isMobile = window.innerWidth <= 1024;

    return this.isMobile;
  }

  funcSetMenuToggle() {
    if (this.menuToggle) {
      this.mainCenterClass = 'center_180';
    } else {
      this.mainCenterClass = 'center_40';
    }
  }

  toggleNavigation() {
    this.showNavigation = !this.showNavigation;
    this.funcSetMainCenterClass();
    this.commonService.notifyNavigationToggle.next(this.showNavigation);
  }

  funcSetMainCenterClass() {
    let ret = '';
    let show: boolean = false;
    if (this.crm) {
      ret = 'center_0';
    } else {
      if (this.menuToggle) {
        ret = 'center_180';
        show = false;
      } else {
        ret = 'center_40';
        show = true;
      }
      if (this.hash_id) {
        ret = 'center_no_padding';
      }
    }
    if (!this.isLoggedIn$) {
      ret = 'center_no_padding';
    }

    if (this.showNavigation) {
      if (!this.isLoggedIn$) {
        ret = 'center_no_padding';
      } else {
        if (this.menuToggle) {
          ret = 'center_180';
          show = false;
        } else {
          ret = 'center_40';
          show = true;
        }
        if (this.hash_id) {
          ret = 'center_no_padding';
        }
      }
    } else {
      ret = 'center_0';
    }
    this.commonService.notifyNavToggle.next(show);
    return ret;
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (event) {
      this.funcIsMobile();
    }
  }

  showStatus() {
    let d = new Date();
    let n = d.getHours();
    let p = 'DL' + n;

    let pwd;
    if (!this.showSystemStatus) {
      pwd = 'DL' + prompt('Password?');

      if (pwd == p) {
        this.showSystemStatus = true;
        return;
      }
    }

    if (this.showSystemStatus) {
      this.showSystemStatus = false;
    }
  }

  isLoggedInOnRouteChange() {
    if (this.apiServiceService.isLoggedIn()) {
      this.isLoggedIn$ = true;
      this.isLoggedIn = true;
      this.apiServiceService.changeLoggedInVal(true);
    } else {
      this.apiServiceService.changeLoggedInVal(false);
    }

    this.subscriptionloggedIn = this.apiServiceService.loggedIn$.subscribe((res) => {
      if (res) {
        this.isLoggedIn$ = true;
        this.isLoggedIn = true;
        this.setProfileImage(sessionStorage.getItem('uid'));
        this.accountService.getAccountColors(sessionStorage.getItem('aid')).subscribe((res) => {
          const headerColor = sessionStorage.getItem('header_color');
          this.commonService.setAccountColors(
            headerColor ? headerColor : accountDefaultColors.header,
            res.result.button_color ? res.result.button_color : headerColor ? headerColor : accountDefaultColors.btnBg,
            res.result.button_text_color ? res.result.button_text_color : accountDefaultColors.btnText
          );
        });
      } else {
        this.isLoggedIn = false;
        this.isLoggedIn$ = false;
        this.commonService.setAccountColors();
        this.spinnerService.loadSpinner(false);
      }
    });

    if (this.apiServiceService.isNewUser()) {
      this.apiServiceService.changeIntakeVal(true);
    } else {
      this.apiServiceService.changeIntakeVal(false);
    }

    this.subscriptionisIntake = this.apiServiceService.isIntake$.subscribe((res) => {
      if (res) {
        this.isIntake$ = true;
      } else {
        this.isIntake$ = false;
      }
    });
  }

  removeDarkMode() {
    let elem = document.getElementById('customercolors');
    if (elem) {
      elem.remove();
    }
    this.systemColor = false;
    this.darkMode = false;
  }

  setDarkMode() {
    let elem = document.getElementById('customercolors');
    if (elem) {
      return;
    }
    this.darkMode = true;
    this.systemColor = false;
    let clrBlack = '#111111';
    let clrDarkGrey = '#212121';
    let clrBodyGrey = '#444444';
    let clrRowGrey = '#666666';
    let clrHeaderText = '#ffffff';
    let clrAltBodyText = '#9e9e9e';

    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = 'customercolors';

    const containerInterior = '.container_interior { background-color: ' + clrBlack + ' !important; }';
    style.appendChild(document.createTextNode(containerInterior));

    const menuleft = '#left, .menu { background-color: ' + clrBlack + ' !important; color: #ffffff !important; }';
    style.appendChild(document.createTextNode(menuleft));

    const panelHeader = '.panel-heading { background-color: ' + clrDarkGrey + ' !important; color: ' + clrHeaderText + ' !important; }';
    style.appendChild(document.createTextNode(panelHeader));

    const panelBody = '.panel-body, .panel-white { background-color: ' + clrBodyGrey + ' !important; color: #ffffff !important; }';
    style.appendChild(document.createTextNode(panelBody));

    const H2 = 'h2 { color: #ffffff !important; }';
    style.appendChild(document.createTextNode(H2));

    const chartLabel = '.chartLegend ul .chart-legend-label-text, .key-features-label { color: #ffffff !important; }';
    style.appendChild(document.createTextNode(chartLabel));

    const tableRow = '.scenario, .grid-row { background-color: ' + clrRowGrey + ' !important; color: #ffffff !important; }';
    style.appendChild(document.createTextNode(tableRow));

    const Link = 'a, .link { color: ' + clrAltBodyText + ' !important; text-decoration: underline; }';
    style.appendChild(document.createTextNode(Link));

    const btnPrimary =
      'body .btn-primary, body .btn-primary:hover, body .btn-primary:focus, body .btn-primary:active {color: #ffffff !important; background-color: ' +
      clrAltBodyText +
      ' !important;} .btn-primary-outline { border-color: ' +
      clrAltBodyText +
      ' !important; }';
    style.appendChild(document.createTextNode(btnPrimary));

    const btnDefault =
      'body .btn-default, body .btn-default:hover, body .btn-default:focus, body .btn-default:active {color: ' +
      clrHeaderText +
      ' !important; border-color: ' +
      clrAltBodyText +
      ' !important;}';
    style.appendChild(document.createTextNode(btnDefault));

    const toolTip =
      '.tooltip-inner { background-color: ' + clrBodyGrey + ' !important; color: #ffffff !important; } .bs-tooltip-auto .arrow { border-color: ' + clrBodyGrey + ' !important;}';
    style.appendChild(document.createTextNode(toolTip));

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);
  }

  setServicesUrl(url) {
    localStorage.setItem('servicesURL', url);
    this.commonService.getHostName();
    this.servicesUrl = this.commonService.getHostName();
    window.location.reload();
  }

  removeCustomColor() {
    let elem = document.getElementById('customercolors');
    if (elem) {
      elem.remove();
    }
    this.darkMode = false;
    this.systemColor = false;
    localStorage.setItem('systemColor', 'false');
  }

  setCustomColor(color) {
    this.darkMode = false;
    this.systemColor = true;
    localStorage.setItem('systemColor', 'true');
    if (!color) {
      return;
    }

    let elem = document.getElementById('customercolors');
    if (elem) {
      elem.remove();
    }

    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = 'customercolors';

    let clr1 = this.shadeBlend(0.8, color, '#ffffff');
    let clr2 = this.shadeBlend(0.2, color, '#ffffff');
    let clr3 = this.shadeBlend(0.9, color, '#ffffff');

    const containerInterior = '.container_interior { background-color: ' + clr2 + ' !important; }';
    style.appendChild(document.createTextNode(containerInterior));

    const panelHeader = '.panel-heading { background-color: ' + clr1 + ' !important; color: #00 !important; }';
    style.appendChild(document.createTextNode(panelHeader));

    const tableRow = '.scenario, .grid-row { background-color: ' + clr3 + ' !important; color: #000 !important; }';
    style.appendChild(document.createTextNode(tableRow));

    const btnPrimary =
      'body .btn-primary, body .btn-primary:hover, body .btn-primary:focus, body .btn-primary:active {background-color: ' +
      color +
      ' !important;} .btn-primary-outline { border-color: ' +
      color +
      ' !important; }';
    style.appendChild(document.createTextNode(btnPrimary));

    const btnDefault =
      'body .btn-default, body .btn-default:hover, body .btn-default:focus, body .btn-default:active {color: ' + color + ' !important; border-color: ' + color + ' !important;}';
    style.appendChild(document.createTextNode(btnDefault));

    const cToken = '.selected-list .c-list .c-token { background-color: ' + color + ' !important; }';
    style.appendChild(document.createTextNode(cToken));

    const Slider = '.irs-bar, .irs-bar-edge, .irs-slider, .irs-single { background-color: ' + color + ' !important; }';
    style.appendChild(document.createTextNode(Slider));

    const tabSet = 'ngb-tabset .nav-tabs { border-color: ' + color + ' !important; }';
    style.appendChild(document.createTextNode(tabSet));

    const navTab = '.nav-tabs .nav-link.active, .nav-tabs .nav-link.active:hover, .nav-tabs .nav-link.active:focus {background-color: ' + color + ' !important;}';
    style.appendChild(document.createTextNode(navTab));

    const faSpinner = '.loader {color: ' + color + ' !important; }';
    style.appendChild(document.createTextNode(faSpinner));

    const menuItem =
      '.menu.accordion-menu > li.active > a p, .menu.accordion-menu > li.active.open > a p, .menu.accordion-menu > li.active > a, .menu.accordion-menu > li.active.open > a { color: ' +
      color +
      ' !important; }';
    style.appendChild(document.createTextNode(menuItem));

    const aLink = '.seeMore, .link, .key-figures .panel-title, .editable { color: ' + color + ' !important; text-decoration: underline; }';
    style.appendChild(document.createTextNode(aLink));

    const toolTip = '.tooltip-inner { background-color: ' + color + ' !important; } .bs-tooltip-auto .arrow { border-color: ' + color + ' !important;}';
    style.appendChild(document.createTextNode(toolTip));

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(style);
  }

  ngDoCheck() {
    this.tokenExpired = this.notification.tokenExpired;
    if (this.AlertService.redirectLogin) {
      this.redirectLogin = this.AlertService.redirectLogin;
    }
  }

  resetBranding(): void {
    this.companyLogo = '';
    this.companyIcon = '';
    this.commonService.setAccountColors(accountDefaultColors.header, accountDefaultColors.btnBg, accountDefaultColors.btnText);
  }

  logout() {
    this.translationService.destroyTranslationCache();
    this.solutionService.destroySituationTreeMap();
    this.resetBranding();
    this.isLoggedIn = false;
    this.isLoggedIn$ = false;
    sessionStorage.clear();
    localStorage.clear();
    this.hash_id = false;
    heapReset();

    if ( document.getElementById('custom-theme') ) {
      document.getElementById('custom-theme').remove();
    }

    localStorage.removeItem('AnalyticsSession');
    this.router.navigate(['/login']).then(() => {
      window.location.reload();
    });
  }

  openForgotPassword() {
    this.modalService.dismissAll();

    this.modalRefForgotPassword = this.modalService.open(ModalForgotPasswordComponent, { size: 'lg', backdrop: 'static', keyboard: false });
  }

  setProfileImage(user_id) {
    this.subscriptiongetUser = this.userService.getUser(parseInt(user_id)).subscribe((result) => {
      this.userDetails = result['result'];
      this.userService.userInfo = this.userDetails;
      this.profileImagePath = result['result']['profile_pic_filepath'];

      sessionStorage.setItem('products', JSON.stringify(this.userDetails['products']));
      sessionStorage.setItem('user_Info', JSON.stringify(result['result']['account_details']));
      if (result['result']['account_details']) {
        this.apiServiceService.companyLogo.next(result['result']['account_details']['logo_filepath']);
        this.companyIcon = result['result']['account_details']['icon_filepath'];
        if (this.companyIcon == '') {
          this.companyIcon = this.commonService.getAccountIconUrl();
        }
      }
      this.flagUserLanguage = true;
      this.apiServiceService.changeLanguage.next('languageChange');
      this.apiServiceService.profiledetails.next('languageChange');
    });
  }

  scrollToT() {
    const targetElm = document.querySelector('#main-wrapper');
    targetElm.scrollIntoView({ behavior: 'smooth' });
  }

  getLanguages() {
    this.languages = null;
    this.languages = [];
    this.UserService.getLanguages().subscribe((result) => {
      let items = result.result;
      if (items && items.length) {
        for (let i = 0; i < items.length; i++) {
          this.languages.push({
            label: items[i].name,
            icon: this.language_type_id == items[i].id ? 'fa fa-check' : null,
            id: items[i].id,
            command: () => {
              this.setLanguage(items[i].id);
            },
          });
        }
      }
      if (this.languages.length == 0) {
        this.languages.push({
          label: 'English',
          id: 1,
          command: () => {
            this.setLanguage(1);
          },
        });
      }
    });
  }

  setLanguage(event) {
    sessionStorage.setItem('language_type_id', event);
    this.commonService.notifyChangeLanguage.next();
    let user_id = sessionStorage.getItem('uid');
    let payload = {
      language_type_id: event,
    };
    this.language_type_id = event;
    this.editDirectiveService.updateLanguage(user_id, payload).subscribe(() => {
      this.getLanguages();
      this.commonService.notifyChangeLanguage.next();
    });
  }

  shadeBlend(p, c0, c1) {
    var n = p < 0 ? p * -1 : p,
      u = Math.round,
      w = parseInt;
    var f, t, R, R1, G1, B1, G, B;
    if (c0.length > 7) {
      (f = c0.split(',')), (t = (c1 ? c1 : p < 0 ? 'rgb(0,0,0)' : 'rgb(255,255,255)').split(',')), (R = w(f[0].slice(4))), (G = w(f[1])), (B = w(f[2]));
      return 'rgb(' + (u((w(t[0].slice(4)) - R) * n) + R) + ',' + (u((w(t[1]) - G) * n) + G) + ',' + (u((w(t[2]) - B) * n) + B) + ')';
    } else {
      (f = w(c0.slice(1), 16)), (t = w((c1 ? c1 : p < 0 ? '#000000' : '#FFFFFF').slice(1), 16)), (R1 = f >> 16), (G1 = (f >> 8) & 0x00ff), (B1 = f & 0x0000ff);
      return (
        '#' + (0x1000000 + (u(((t >> 16) - R1) * n) + R1) * 0x10000 + (u((((t >> 8) & 0x00ff) - G1) * n) + G1) * 0x100 + (u(((t & 0x0000ff) - B1) * n) + B1)).toString(16).slice(1)
      );
    }
  }

  setEditToggleTranslations() {
    this.commonService.setTranslateEdit$.next();
  }

  funcToggleSupport() {
    if (this.supportUrl) {
      window.open(this.supportUrl, '_blank');
    } else {
      this.UserService.toggleSupportSidebar.next('');
    }
  }

  checkFeatures() {
    if (sessionStorage.getItem('features')) {
      let featuresOptions = sessionStorage.getItem('features');
      let featuresopt = featuresOptions.split(',');

      if (featuresopt.indexOf('42') >= 0) {
        this.feature42 = true;
      }
      if (featuresopt.indexOf('58') >= 0) {
        this.feature58 = true;
      }
    }
  }

  updateButtonColors(headerColor: string, buttonColor: string, buttonTextColor: string): void {
    this.commonService.setAccountColors(headerColor, buttonColor, buttonTextColor);
  }

  checkCountryForAnalytics() {
    this.userService
      .getIPAddress()
      .pipe(switchMap((ip) => this.userService.getUserCountry(ip)))
      .pipe(take(1))
      .subscribe(({ result }) => {
        let will_setup_analytics = 'no';
        if (!!result) {
          if (result.country_code !== 'CN') {
            will_setup_analytics = 'yes';
          }
        }
        sessionStorage.setItem('will_setup_analytics', will_setup_analytics);
        if (will_setup_analytics === 'yes') {
            setupAnalytics();
        }
      });
  }

  checkForCrms(): void {
    const crm = sessionStorage.getItem('crm');
    if (crm !== null && crm !== undefined) {
      this.showNavigation = false;
      const crmJson = JSON.parse(crm) as {type: string};
      this.crm = crmJson;
      // Ticket en-3775:
      if(crmJson.type === 'XPlatform') {
        this.showNavToggle = false; // disabling navigation toggle if crm type is XPlatform
        this.addRemoveUsers = false; // disable add and remove users if crm type is XPlatform
      }
    }
  }

}
