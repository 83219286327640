import { Injectable } from '@angular/core';

@Injectable()
export class CollaborationTranslations {
  public config: any = {
    component: 'collaboration',
    display_name: 'Collaboration',
    description: 'Customer Collaboration',
  };
  public trans: any = {
    externalCollaboration: 'External Collaboration',
    addDetails: 'Add the details of the customer and choose what to share',
    shareWith: 'Share with',
    name: 'NAME',
    addName: 'Add name',
    email: 'EMAIL',
    addEmail: 'Add email',
    expirationDate: 'EXPIRATION DATE',
    expirationPlaceholder: 'dd/mm/yyyy',
    whatToShare: 'What to share',
    include: 'Include?',
    discoveryQuestions: 'Discovery Questions',
    outcomes: 'Outcomes / Benefits',
    keyAssumptions: 'Key Assumptions',
    assets: 'Assets',
    who: 'Who',
    noCollaborationsFound: 'No Customer Collaborations Found',
    search: 'Search',
    areYouSure: 'Are you sure?',
    addCollaboration: 'Add Collaboration',
    confirm: 'Confirm',
    saveSuccess: 'Customer Collaboration Share updated successfully for ',
    saveError: 'Customer Collaboration Share cannot be created for ',
    assetRequired: 'Asset is enabled but no asset has been chosen. Please choose an asset or disable Asset checkbox.',
    allRequiredValues: 'Please enter all required fields.',
    delete: 'Delete',
    back: 'Back to list',
    shareWithCustomer: 'Share With Customer',
    addTheDetails: 'Add the details of the customer and choose what to share.',
    emailCustomer: 'Email Customer',
    defaultColabEmailBody: 'Dear \n\nWe\'ve created a new look at the potential value we can drive for your organization and would love your help to review and refine it to make sure it aligns with your goals and your companies metrics. Please follow the link below to review the below and feel free to reply to me directly if you have any questions.\n\n[Link to proposal]\n\nPassword:\n\nAll the best,\n\nName',
    copyEmailToClipboard: 'Copy Email to Clipboard',
    failedToCopyToClipboard: 'Failed to copy to clipboard',
    copiedToClipboard: 'Copied To Clipboard',
    continue: 'Continue',
    loseEmailProgress: 'You will lose changes to this email unless it is copied to the clipboard. Are you sure?',
  }

}